import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Paper
} from '@mui/material'
import { isEmpty } from 'ramda'
import React from 'react'

const noop = () => {}

function ConfirmModal({
  title = '¿Está seguro?',
  subtitle = 'Esta operación no puede deshacerse',
  showModal = false,
  children = null,
  okDisabled = false,
  okText = 'Aceptar',
  cancelText = 'Cancelar',
  isLoading = false,
  style = {},
  showActions = true,
  onOk = noop,
  onCancel = noop
}) {
  return (
    <Modal
      open={showModal}
      disablePortal={false}
      style={!isEmpty(style) ? style : { width: '50%', margin: '200px auto' }}
    >
      <Paper>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subtitle}</DialogContentText>
          {children}
        </DialogContent>
        {showActions && (
          <DialogActions>
            <Button onClick={onCancel} color='secondary'>
              {cancelText}
            </Button>
            {isLoading ? (
              <CircularProgress size={30} />
            ) : (
              <Button onClick={onOk} color='primary' disabled={okDisabled}>
                {okText}
              </Button>
            )}
          </DialogActions>
        )}
      </Paper>
    </Modal>
  )
}

export default ConfirmModal
