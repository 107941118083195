import React, { useCallback, useRef } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField
} from '@mui/material'
import { Save } from '@mui/icons-material'
import { useTags } from '../../../../hooks/useTags'
import LoadingButton from '../../../../components/shared/LoadingButton'
import useFormManager from '../../../../hooks/useFormManager'
import CategorySelect from '../../../../components/shared/CategorySelect'
import TagList from '../../../../components/shared/TagList'

const REQUIRED_FIELDS_CREATE = ['name', 'category', 'file']
const REQUIRED_FIELDS_EDIT = ['name', 'category']
const DESCRIPTION_LIMIT = 1200

function NotebookForm({
  notebook = {},
  title = '',
  isSaving = false,
  isEditMode = false,
  onSubmit
}) {
  const ref = useRef()
  const {
    handleFormChange,
    handleUploadFile,
    handleCheckChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    form
  } = useFormManager(
    isEditMode ? REQUIRED_FIELDS_EDIT : REQUIRED_FIELDS_CREATE,
    notebook
  )
  const {
    tags,
    inputTagValue,
    handleInputTagValueChange,
    handleAddTag,
    handleDeleteTag
  } = useTags(notebook.tags)

  const handleDeleteFile = useCallback(() => {
    handleFormChange({ target: { name: 'file', value: null } })
    handleFormChange({ target: { name: 'filename', value: null } })
  }, [handleFormChange])

  const handleDescriptionChange = useCallback(
    e => {
      if (e.target.value.length > DESCRIPTION_LIMIT) return
      handleFormChange(e)
    },
    [handleFormChange]
  )

  const handleSubmit = useCallback(() => {
    onSubmit({ ...form, tags })
  }, [form, onSubmit, tags])

  return (
    <Card className='bordered_shadow' variant='outlined'>
      <CardHeader title={title} />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '10px'
        }}
      >
        <TextField
          name='name'
          margin='dense'
          label='Nombre'
          variant='standard'
          value={form.name || ''}
          onChange={handleFormChange}
          fullWidth
          {...getErrorPropsByRequiredField('name')}
        />
        <CategorySelect
          name='category'
          value={form.category || ''}
          onChange={handleFormChange}
          fullWidth
          {...getErrorPropsByRequiredField('category')}
        />
        {!isEditMode && (
          <div style={{ margin: '0 auto', marginTop: 15 }}>
            {form.file ? (
              <Chip label={form.file.name} onDelete={handleDeleteFile} />
            ) : (
              <Button
                color='secondary'
                onClick={() =>
                  ref.current.click()
                }
                variant='contained'
              >
                Seleccionar archivo
                <input
                  name='file'
                  type='file'
                  ref={ref}
                  hidden
                  onChange={e =>
                    handleUploadFile({
                      target: {
                        name: e.target.name,
                        files: e.target?.files?.[0]
                      }
                    })
                  }
                  accept='application/pdf'
                />
              </Button>
            )}
          </div>
        )}
        <TextField
          name='description'
          margin='dense'
          label='Descripción'
          variant='standard'
          value={form.description || ''}
          onChange={handleDescriptionChange}
          maxRows={6}
          helperText={`${form.description?.length || 0}/1200`}
          multiline
          fullWidth
        />
        <div>
          <FormControlLabel
            margin='dense'
            name='visible'
            control={
              <Checkbox
                checked={form.visible || false}
                onChange={handleCheckChange}
              />
            }
            label='Activo'
          />
          {isEditMode && (
            <FormControlLabel
              margin='dense'
              name='archived'
              control={
                <Checkbox
                  checked={form.archived || false}
                  onChange={handleCheckChange}
                />
              }
              label='Archivado'
              disabled={notebook.archived}
            />
          )}
        </div>

        {isEditMode && !!form.archived && (
          <div>
            <TextField
              name='archivedReason'
              margin='dense'
              label='Razón archivado'
              variant='standard'
              value={form.archivedReason || ''}
              onChange={handleFormChange}
              maxRows={5}
              multiline
              fullWidth
            />
            {form.archivedAt && (
              <p> Archivado a {new Date(form.archivedAt).toLocaleString()}</p>
            )}
          </div>
        )}
        {isEditMode && (
          <a target='_blank' href={form.url} rel='noreferrer'>
            <Button style={{ marginTop: 20, width: '100%' }} color='primary'>
              Ver archivo actual
            </Button>
          </a>
        )}
        <TagList
          tags={tags}
          inputTagValue={inputTagValue}
          onTagDelete={handleDeleteTag}
          onChange={handleInputTagValueChange}
          onTagAdd={handleAddTag}
        />
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <LoadingButton
          label='Guardar'
          onClick={handleSubmit}
          isLoading={isSaving}
          disabled={hasPendingRequiredFields || !!notebook?.archived}
          startIcon={<Save />}
        />
      </CardActions>
    </Card>
  )
}

export default NotebookForm
