import { COMMON_ERROR_MESSAGE, GET } from './index'

export const fetchMe = () => GET({ url: 'me' })

export const googleLogin = credentials => {
  return fetch('https://api.identity.mundoestudiante.cloud/api/auth/brain-login', {
    method: 'POST',
    body: JSON.stringify(credentials),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    const JSONResponse = await response.json()
    if (response.ok) return JSONResponse.body
    throw new Error(JSONResponse.message || COMMON_ERROR_MESSAGE)
  })
}
