import React, { useState } from 'react'
import Header from '../../components/Header/Header'
import PortadaExamen from '../../components/PortadaExamen/PortadaExamen'
import SearchBar from '../../components/SearchBar/SearchBar'
import { useVisibleData } from '../../context/BrainProvider'
import './ListaExamenes.css'
import { useParams } from 'react-router-dom'

const ListaExamenes = ()=> {
  const [search, setSearch] = useState('')

  const { exams } = useVisibleData()
  const { tipo, id } = useParams()

  return (
    <>
      <Header text={id} />
      <SearchBar search={search} onChange={setSearch} />
      <div className='ListaExamenes'>
        <ul>
          {exams
            .filter(exam => exam.type === tipo)
            .filter(exam => exam.category === id)
            .filter(exam => {
              if (!search) return true
              const validName = exam.name.toLowerCase().includes(search.toLowerCase())
              const validTag = exam?.tags.map(t => t.toLowerCase()).some(t => t.includes(search.toLocaleLowerCase()))
              const validDescription = exam.description?.toLocaleLowerCase()?.includes(search.toLocaleLowerCase())
              return validName || validTag || validDescription
            })
            .map(exam => (
              <PortadaExamen
                key={exam.id}
                id={exam.id}
                name={exam.name}
                category={exam.category}
                year={exam.year}
                option={exam.option}
                solved={exam.solved}
                community={exam.community}
                scope={exam.scope}
                section={exam.section}
              />
            ))}
        </ul>
      </div>
    </>
  )
}

export default ListaExamenes
