import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { assoc } from 'ramda'
import ExamForm from '../ui/ExamForm'
import Spinner from '../../../../components/shared/Spinner'
import Header from '../../../../components/Header/Header'
import { getExam, updateExam } from '../../../../api/exams'
import { useMessageSnackbarActions } from '../../../../context/MessageProvider'
import '../Common.css'

const initialState = {
  exam: null,
  isLoading: true,
  error: false,
  isSaving: false
}

function ExamEdit() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [state, setState] = useState(initialState)
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const handleSave = updatedExam => {
      setState(assoc('isSaving', true))
      updateExam(id, updatedExam)
        .then(() => {
          setState(assoc('isSaving', false))
          navigate('/admin/exams')
          setSuccessMessage()
        })
        .catch(e => {
          setState(assoc('isSaving', false))
          console.error('Error updating exam: ', e)
          setErrorMessage('No se ha podido actualizar')
        })
    }

  useEffect(() => {
    if (!id) return
    getExam(id)
      .then(exam => setState({ ...initialState, exam, isLoading: false }))
      .catch(e => {
        console.error('Error getting exam: ' + e)
        setErrorMessage('Error al obtener el exam')
        setState({ ...initialState, isLoading: false, error: true })
      })
  }, [id, setErrorMessage])

  if (state.error) return <p>Se ha producido un error</p>
  if (!state.exam || state.isLoading) return <Spinner />
  return (
    <>
      <Header text='Gestión' />
      <div className='adjust_form'>
        <ExamForm
          title='Editar examen'
          exam={state.exam}
          onSubmit={handleSave}
          isEditMode
          isSaving={state.isSaving}
        />
      </div>
    </>
  )
}

export default ExamEdit
