import React, { useEffect, useState } from 'react'
import { getTrainingItem } from '../../api/training'
import Header from '../../components/Header/Header'
import DocumentViewer from '../../components/shared/DocumentViewer'
import Spinner from '../../components/shared/Spinner'
import { useMessageSnackbarActions } from '../../context/MessageProvider'
import { useParams } from 'react-router-dom'

const Viewer = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [item, setItem] = useState({})
  const { setErrorMessage } = useMessageSnackbarActions()
  const { id } = useParams()

  useEffect(() => {
    if (!id) return
    setIsFetching(true)
    getTrainingItem(id)
      .then(setItem)
      .catch(e => {
        console.error('Error getting item: ', e)
        setErrorMessage()
      })
      .finally(() => setIsFetching(false))
  }, [id, setErrorMessage])

  if (isFetching || !item?.url) return <Spinner />
  return (
    <>
      <Header text={item?.name} />
      <DocumentViewer url={item?.url} />
    </>
  )
}

export default Viewer
