//@ts-check
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/mundoestudiante-logo-sintexto.png'
import colors from '../../data/colors'
import styles from './PortadaExamen.module.css'

const getYearAndSolvedText = (year, solved) => {
  let text = ''
  if (year) text += year
  if (year && solved) text += ' - '
  if (solved) text += 'Resuelto'
  return text
}

const PortadaExamen = ({
  id,
  name,
  category,
  year,
  option,
  solved,
  community,
  scope,
  section,
  ...rest
}) => {
  const yearAndSolvedText = getYearAndSolvedText(year, solved)
  return (
    <li className={styles.portadaExamen} {...rest}>
      <Link className={styles.link} to={`/examen/${id}`}>
        <div
          className={styles.cover}
          style={{
            backgroundColor: colors.find(
              color => color.name.toLowerCase() === category.toLowerCase()
            )?.color
          }}
        >
          <p className={styles.asignatura}>{category}</p>
          <p className={styles.title}>{name}</p>
          {yearAndSolvedText && (
            <p className={styles.paragraph}>{yearAndSolvedText}</p>
          )}
          {scope && <p className={styles.paragraph}>Ámbito - {scope}</p>}
          {section && <p className={styles.paragraph}>{section}</p>}
          {community && (
            <p className={[styles.paragraph, styles.community].join(' ')}>
              {community}
            </p>
          )}
        </div>
        <div className={styles.footer}>
          <img className={styles.footerLogo} src={logo} alt='mundoestudiante' />
          <span className={styles.footerDetail}>Opción - {option}</span>
        </div>
      </Link>
    </li>
  )
}

export default PortadaExamen
