import { GET, POST, PUT } from './index'

export const findExams = () => GET({ url: 'exams' })
export const findVisibleExams = () => GET({ url: 'exams/visible' })
export const getExam = (id = '') => GET({ url: 'exams/' + id })
export const createExam = (data = {}) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    if (key === 'tags')
      data[key].forEach(tag => {
        formData.append('tags', tag)
      })
    else formData.append(key, data[key])
  })
  return POST({ url: 'exams', data: formData, useAcceptAPP_JSON: true })
}
export const updateExam = (id, data = {}) => {
  return PUT({
    url: 'exams/' + id,
    data
  })
}
