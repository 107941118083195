import React, { useEffect, useState } from 'react'
import { Chip } from '@mui/material'
import fileSize from 'filesize'
import { Link } from 'react-router-dom'
import { findTrainingItemsByTeacher } from '../../api/training'
import Header from '../../components/Header/Header'
import InformationTable from '../../components/InformationTable/InformationTable'
import SearchInput from '../../components/SearchInput/SearchInput'
import Spinner from '../../components/shared/Spinner'
import { useAuth } from '../../context/AuthProvider'
import { useMessageSnackbarActions } from '../../context/MessageProvider'

function Account() {
  const [isFetching, setIsFetching] = useState(false)
  const [search, setSearch] = useState('')
  const [training, setTraining] = useState([])
  const { user } = useAuth()
  const { setErrorMessage } = useMessageSnackbarActions()
  useEffect(() => {
    if (!user?.id) return
    setIsFetching(true)
    findTrainingItemsByTeacher(user.id)
      .then(setTraining)
      .catch(e => {
        console.error('Error fetching data: ', e)
        setErrorMessage()
      })
      .finally(() => setIsFetching(false))
  }, [setErrorMessage, user?.id])
  if (isFetching) return <Spinner />
  return (
    <>
      <Header text={`Perfil (${user?.email})`} />
      <div className='common_toolbar'>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
      </div>
      {/* //TODO: use section to change pass etc */}
      <InformationTable
        details={[
          {
            title: 'Nombre',
            key: 'name',
            align: 'left'
          },
          {
            title: 'Categoría',
            key: 'category',
            align: 'left'
          },
          {
            title: 'Tamaño',
            key: 'size',
            align: 'left'
          }
        ]}
        data={training
          .filter(item => {
            if (!search) return true
            const validName = item.name
              .toLowerCase()
              .includes(search.toLowerCase())
            const validCategory = item.category
              .toLowerCase()
              .includes(search.toLowerCase())
            return validName || validCategory
          })
          .map(item => ({
            name: (
              <Link
                to={`/account/files/${item.id}`}
                style={{ color: 'var(--success-color)', fontWeight: 'bold' }}
              >
                {item.name}
              </Link>
            ),
            size: (
              <Chip
                label={item.size ? fileSize(item.size, { round: 0 }) : '-'}
                size='small'
              />
            ),
            category: item.category
          }))}
      />
    </>
  )
}

export default Account
