import React from 'react'
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText
} from '@mui/material'

export const examTypes = {
  'Cambridge B2': 'Cambridge B2',
  'Cambridge C1': 'Cambridge C1',
  EvAU: 'EvAU',
  'Grado Medio': 'Grado Medio',
  'Grado Superior': 'Grado Superior',
  'Graduado ESO': 'Graduado ESO',
  PCE: 'PCE'
}

const options = Object.keys(examTypes)
function ExamTypeSelect({
  value = '',
  onChange,
  label = 'Tipo',
  name,
  fullWidth,
  margin = 'none',
  ...rest
}) {
  const { helperText, ...otherRest } = rest || {}
  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      variant='standard'
      {...otherRest}
    >
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        {options.map(category => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default ExamTypeSelect
