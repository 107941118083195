import { GoogleLogin } from '@react-oauth/google'
import { googleLogin } from '../../api/auth'
import { useNavigate } from 'react-router-dom'
import { useNotification } from '../../context/NotificationManager/NotificationManager'
import logo from './brain-black.png'
import { useAuth } from '../../context/AuthProvider'
import css from './Login.module.css'

const Login = () => {
  const navigate = useNavigate()
  const notification = useNotification()

  const { saveAuthentication } = useAuth()

  const onSuccess = credentials => {
    googleLogin(credentials)
      .then(auth => {
        saveAuthentication(auth)
        notification.success({
          title: 'Sesión iniciada',
          content: `🧠 te da la bienvenida`
        })
        navigate('/')
      })
      .catch(err =>
        notification.error({
          title: 'Error iniciando sesión',
          content: err.message
        })
      )
  }

  const onError = () => {
    notification.error({
      title: 'Error iniciando sesión',
      content: 'Ha habido un error verificando tus datos con Google'
    })
  }

  return (
    <main className={css.main}>
      <img className={css.logo} alt='Brain' src={logo} />
      <GoogleLogin
        text='Iniciar sesión'
        onSuccess={onSuccess}
        onFailure={onError}
      />
    </main>
  )
}

export default Login
