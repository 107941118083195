import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Tab, Tabs } from '@mui/material'
import { assoc } from 'ramda'
import UserForm from '../ui/UserForm'
import Spinner from '../../../../components/shared/Spinner'
import Header from '../../../../components/Header/Header'
import { getUser, updateUser } from '../../../../api/users'
import { useMessageSnackbarActions } from '../../../../context/MessageProvider'
import { ROLES, ROLES_LABELS } from '../../../../utils/constants'
import Training from './Training'
import '../Common.css'

const initialState = {
  user: null,
  isLoading: true,
  error: false,
  isSaving: false,
  activeTab: 0
}

function UserEdit() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [state, setState] = useState(initialState)
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const handleSave = updatedExam => {
    setState(assoc('isSaving', true))
    updateUser(id, updatedExam)
      .then(() => {
        setState(assoc('isSaving', false))
        navigate('/admin/users')
        setSuccessMessage()
      })
      .catch(e => {
        setState(assoc('isSaving', false))
        console.error('Error updating exam: ', e)
        setErrorMessage(e.message)
      })
  }

  const handleChange = useCallback(
    (_, newValue) => setState(state => ({ ...state, activeTab: newValue })),
    []
  )

  useEffect(() => {
    if (!id) return
    getUser(id)
      .then(user => setState({ ...initialState, user, isLoading: false }))
      .catch(e => {
        console.error('Error getting user: ' + e)
        setErrorMessage('Error al obtener el usuario')
        setState({ ...initialState, isLoading: false, error: true })
      })
  }, [id, setErrorMessage])

  if (state.error) return <p>Se ha producido un error</p>
  if (!state.user || state.isLoading) return <Spinner />
  return (
    <>
      <Header text='Gestión' />
      {
        state.user?.role === ROLES.teacher && (
          <Tabs
            value={state.activeTab}
            onChange={handleChange}
            color='red'
            variant='fullWidth'
          >
            <Tab label='Perfil' />
            <Tab label='Formaciones' />
          </Tabs>
        )
      }
      {state.activeTab === 0 && (
        <div className='adjust_form'>
          <UserForm
            title={'Editar ' + ROLES_LABELS[state.user?.role]}
            user={state.user}
            isSaving={state.isSaving}
            onSubmit={handleSave}
          />
        </div>
      )}
      {state.activeTab === 1 && <Training teacherId={id} />}
    </>
  )
}

export default UserEdit
