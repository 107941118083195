import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { isEmpty } from 'ramda'
import { googleLogout } from '@react-oauth/google'
import { useNavigate } from 'react-router-dom'
import { fetchMe } from '../api/auth'
import Login from '../views/Login/Login'
import Spinner from '../components/shared/Spinner'
import { ROLES } from '../utils/constants'

const AuthContext = createContext({})

function AuthProvider({ children }) {
  const [isFetchingMe, setIsFetchingMe] = useState(true)
  const [user, setUser] = useState({})
  const navigate = useNavigate()

  const isAuthenticated = !!localStorage.getItem('access_token')

  const handleLogout = () => {
    localStorage.removeItem('access_token')
    setUser({})
    googleLogout()
    navigate('/login')
  }

  const handleFetchMe = () => {
    if (!isFetchingMe) return false
    setIsFetchingMe(true)
    fetchMe()
      .then(setUser)
      .catch(e => {
        console.error('Error fetching me: ', e)
        if (
          e.message.includes('jwt malformed') ||
          (e.message.includes('invalid signature') && isAuthenticated)
        )
          handleLogout()
      })
      .finally(() => setIsFetchingMe(false))
  }

  const saveAuthentication = ({ token, user }) => {
    user && setUser(user)
    localStorage.setItem('access_token', token)
    navigate('/')
    window.location.reload()
  }

  const isAdmin = useMemo(() => user?.role === ROLES.admin, [user])

  useEffect(() => {
    handleFetchMe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isFetchingMe) return <Spinner />

  return (
    <AuthContext.Provider
      value={{
        user,
        isAdmin,
        isFetchingMe,
        isAuthenticated,
        handleFetchMe,
        setUser,
        saveAuthentication,
        handleLogout
      }}
    >
      {!isAuthenticated && isEmpty(user) ? <Login /> : children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

/**
 *
 * @returns {{
 * user: object
 * isAdmin: boolean
 * isFetchingMe: boolean
 * isAuthenticated: boolean
 * handleFetchMe: ()=> Promise<object>
 * setUser: (user: object)=> void
 * saveAuthentication: ({token, user}: {token:string, user?:object})=> void
 * handleLogout: ()=> void
 * }}
 */
export const useAuth = () => useContext(AuthContext)
