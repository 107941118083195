import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
const responsiveMap = {
  xs: { config: { maxWidth: '575px' }, key: 'xs' },
  sm: { config: { minWidth: '576px' }, key: 'sm' },
  md: { config: { minWidth: '768px' }, key: 'md' },
  lg: { config: { minWidth: '992px' }, key: 'lg' },
  xl: { config: { minWidth: '1200px' }, key: 'xl' },
  xxl: { config: { minWidth: '1600px' }, key: 'xxl' }
}
const getMobileDetect = userAgent => {
  const isAndroid = Boolean(userAgent.match(/Android/i))
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isOpera = Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = Boolean(userAgent.match(/IEMobile/i))
  const isSSR = Boolean(userAgent.match(/SSR/i))
  const isMobile = Boolean(isAndroid || isIos || isOpera || isWindows)
  const isDesktop = Boolean(!isMobile && !isSSR)
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR
  }
}
function useBreakPoint() {
  const xs = useMediaQuery(responsiveMap.xs.config)
  const sm = useMediaQuery(responsiveMap.sm.config)
  const md = useMediaQuery(responsiveMap.md.config)
  const lg = useMediaQuery(responsiveMap.lg.config)
  const xl = useMediaQuery(responsiveMap.xl.config)
  const xxl = useMediaQuery(responsiveMap.xxl.config)
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
  const currentScreen = useMemo(() => {
    const screens = [
      { screen: 'xs', value: xs },
      { screen: 'sm', value: sm },
      { screen: 'md', value: md },
      { screen: 'lg', value: lg },
      { screen: 'xl', value: xl },
      { screen: 'xxl', value: xxl }
    ]
    let screen
    screens.forEach(item => {
      if (item.value) {
        screen = responsiveMap[item.screen]
        return
      }
    })
    return screen || responsiveMap.lg
  }, [lg, md, sm, xl, xs, xxl])
  return { currentScreen, ...getMobileDetect(userAgent) }
}
export default useBreakPoint
