import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { assoc, without } from 'ramda'
import {
  Chip,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material'
import {
  ExpandLess,
  ExpandMore,
  MenuBook,
  OndemandVideo,
  Science
} from '@mui/icons-material'
import { searchGlobally } from '../../api/globalSearch'
import { useAuth } from '../../context/AuthProvider'
import { useMessageSnackbarActions } from '../../context/MessageProvider'
import { useVisibleData } from '../../context/BrainProvider'
import Header from '../../components/Header/Header'
import SearchInput from '../../components/SearchInput/SearchInput'
import Spinner from '../../components/shared/Spinner'
import styles from './GlobalSearch.module.css'

const NOTEBOOK = 'notebook'
const EXAM = 'exam'
const VIDEO = 'video'

function GlobalSearch() {
  const [search, setSearch] = useState('')
  const [state, setState] = useState({
    notebooks: [],
    exams: [],
    videos: [],
    notebook: true,
    exam: true,
    video: true,
    isFetching: false
  })
  const { user } = useAuth()
  const { fetchData } = useVisibleData()
  const { setErrorMessage } = useMessageSnackbarActions()

  const { notebooks, exams, videos, notebook, exam, video, isFetching } = state

  const handleSearch = e => {
    const { value } = e.target
    setSearch(value)
    if (!value || !value.trim())
      return setState(state => ({
        ...state,
        notebooks: [],
        exams: [],
        videos: []
      }))
    setState(state => ({
      ...state,
      notebooks: [],
      exams: [],
      videos: [],
      isFetching: true
    }))
    fetchData() //in order to update content if it does not exist
    searchGlobally(value)
      .then(data => setState(state => ({ ...state, ...data })))
      .catch(e => {
        console.error('Error in global search: ', e)
        setErrorMessage()
      })
      .finally(() => setState(assoc('isFetching', false)))
  }

  const toggleFilter = type =>
    setState(state => ({ ...state, [type]: !state[type] }))

  const length = notebooks.concat(exams).concat(videos).length

  return (
    <div>
      <Header text={`Perfil (${user?.email})`} />
      <div className={styles.search}>
        <SearchInput
          label='Búsqueda global'
          value={search}
          onChange={handleSearch}
          variant='outlined'
          autoFocus
          useDebounced
          fullWidth
        />
      </div>
      {isFetching && <Spinner height='100px' />}
      {search && (
        <>
          {length > 0 && (
            <div className={styles.filters}>
              <MenuBook
                color={notebook ? 'success' : 'disabled'}
                onClick={() => toggleFilter(NOTEBOOK)}
              />
              <Science
                color={exam ? 'success' : 'disabled'}
                onClick={() => toggleFilter(EXAM)}
              />
              <OndemandVideo
                color={video ? 'success' : 'disabled'}
                onClick={() => toggleFilter(VIDEO)}
              />
            </div>
          )}
          <div className={styles.info}>
            {length === 0 && !isFetching ? (
              <Typography variant='overline'>
                No se ha encontrado ningún resultado
              </Typography>
            ) : (
              <div>
                <Divider variant='fullWidth' />
                <Typography variant='overline'>
                  Se han encontrado {length} resultados
                </Typography>
                <Divider variant='middle' />
              </div>
            )}
          </div>
          <div>
            {notebook && <ContentList data={notebooks} type={NOTEBOOK} />}
            {exam && <ContentList data={exams} type={EXAM} />}
            {video && <ContentList data={videos} type={VIDEO} />}
          </div>
        </>
      )}
    </div>
  )
}

export default GlobalSearch

const urlByType = {
  [NOTEBOOK]: n => `/cuadernillos/${n.id}`,
  [EXAM]: e => `/examen/${e.id}`,
  [VIDEO]: v => `/video/${v.id}`
}

const iconByType = {
  [NOTEBOOK]: MenuBook,
  [EXAM]: Science,
  [VIDEO]: OndemandVideo
}

function ContentList({ data = [], type = '' }) {
  const [opened, setOpened] = useState([])
  const handleOpen = (id = '') =>
    setOpened(state => {
      if (state.includes(id)) return without([id], state)
      return state.concat(id)
    })
  return (
    <List>
      {data.map(item => {
        const href = urlByType[type](item)
        const Icon = iconByType[type]
        return (
          <div key={item.id}>
            <ListItem>
              <ListItemButton onClick={() => handleOpen(item.id)}>
                <Link to={href} target='_blank'>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                </Link>
                <ListItemText
                  primary={item.name}
                  secondary={
                    <>
                      <Typography
                        component='span'
                        variant='body2'
                        color='textPrimary'
                      >
                        {item.category}
                      </Typography>
                      <br />
                      {item.tags?.map(tag => (
                        <Chip
                          key={tag}
                          label={tag}
                          sx={{ m: 0.3 }}
                          size='small'
                        />
                      ))}
                    </>
                  }
                />
                {opened.includes(
                  item.id
                ) ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ width: '100%' }} component='div' disablePadding>
              <Collapse
                in={opened.includes(
                  item.id
                )}
                timeout='auto'
                unmountOnExit
              >
                <List component='div' disablePadding>
                  <ListItemButton>
                    <ListItemText color='textPrimary'>
                      {item.description || 'Sin descripción'}
                    </ListItemText>
                  </ListItemButton>
                </List>
              </Collapse>
            </ListItem>
            <Divider variant='inset' component='li' />
          </div>
        )
      })}
    </List>
  )
}
