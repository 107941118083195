import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/mundoestudiante-logo-texto-black.png'
import colors from '../../data/colors'
import './PortadaCuadernillo.css'

const PortadaCuadernillo = ({ id, name, category, ...rest }) => (
  <li className='PortadaCuadernillo-element' {...rest}>
    <Link className='PortadaCuadernillo-link' to={`/cuadernillos/${id}`}>
      <div
        className='PortadaCuadernillo-cover'
        style={{
          backgroundColor: colors.find(
            color => color.name.toLowerCase() === category.toLowerCase()
          )?.color
        }}
      >
        <p className='PortadaCuadernillo-asignatura'>{category}</p>
        <p className='PortadaCuadernillo-title'>{name}</p>
      </div>
      <div className='PortadaCuadernillo-footer'>
        <img
          className='PortadaCuadernillo-footer-logo'
          src={logo}
          alt='mundoestudiante'
        />
        <span className='PortadaCuadernillo-footer-detail'>Biblioteca</span>
      </div>
    </Link>
  </li>
)

export default PortadaCuadernillo
