import React from 'react'
import Header from '../../components/Header/Header'
import DocumentViewer from '../../components/shared/DocumentViewer'
import Spinner from '../../components/shared/Spinner'
import { useVisibleData } from '../../context/BrainProvider'
import { useParams } from 'react-router-dom'

const Examen = () => {
  const { exams, isFetching } = useVisibleData()
  const { id } = useParams()
  const targetExam = exams.find(exam => exam.id === id)
  if (isFetching) return <Spinner />
  return (
    <>
      <Header text={targetExam?.name} />
      <DocumentViewer url={targetExam?.url} />
    </>
  )
}

export default Examen
