export const dateFromString = string => {
  if (string === undefined || string === null) return null
  if (string.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/)) {
    let data = string.split('/')
    let year = +data[2]
    let month = +data[1] - 1
    let day = +data[0]
    return new Date(year, month, day, 0, 0, 0, 0)
  } else return null
}

export const dateToString = dateOriginal => {
  if (dateOriginal === null || dateOriginal === undefined) return ''
  let date = new Date(dateOriginal)
  if (date) {
    let day = date.getDate() + ''
    if (day.length === 1) day = '0' + day
    let month = date.getMonth() + 1 + ''
    if (month.length === 1) month = '0' + month
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }
  return ''
}
