import { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Header from '../../components/Header/Header'
import VideoCard from '../../components/VideoCard/VideoCard'
import SearchBar from '../../components/SearchBar/SearchBar'
import { useVisibleData } from '../../context/BrainProvider'
import './VideoAsignatura.css'
import { useParams } from 'react-router-dom'

const VideoAsignatura = ()=> {
  const [activeTab, setActiveTab] = useState(0)
  const [search, setSearch] = useState('')

  const { videos } = useVisibleData()
  const { tipo } = useParams()

  const handleChange = (_, newValue) => setActiveTab(newValue)

  return (
    <>
      <Header text={tipo} />
      <div className='Videos-toolbar'>
        <Tabs
          variant='fullWidth'
          value={activeTab}
          onChange={handleChange}
        >
          <Tab label='Videos' />
        </Tabs>
        <SearchBar search={search} onChange={setSearch} />
      </div>

      <div className='videos'>
        {videos
          .filter(
            video => video.category.toLowerCase() === tipo
          )
          .filter(video =>
            video.name.toLowerCase().includes(search.toLowerCase())
          )
          .map(video => (
            <VideoCard key={video.id} id={video.id} name={video.name} />
          ))}
      </div>
    </>
  )
}

export default VideoAsignatura
