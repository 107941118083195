import React, { useEffect, useMemo, useState } from 'react'
import fileSize from 'filesize'
import { Link } from 'react-router-dom'
import { Checkbox, Chip, FormControlLabel } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useMessageSnackbarActions } from '../../../../context/MessageProvider'
import InformationTable from '../../../../components/InformationTable/InformationTable'
import LoadingButton from '../../../../components/shared/LoadingButton'
import SearchInput from '../../../../components/SearchInput/SearchInput'
import StatusIcon from '../../../../components/shared/StatusIcon'
import Header from '../../../../components/Header/Header'
import { findNotebooks } from '../../../../api/notebooks'

function NotebookList() {
  const { setErrorMessage } = useMessageSnackbarActions()
  const [notebooks, setNotebooks] = useState([])
  const [search, setSearch] = useState('')
  const [showArchived, setShowArchived] = useState(false)

  const filteredList = useMemo(() => {
    if (showArchived) return notebooks.filter(n => !!n.archived)
    return notebooks.filter(n => !n.archived)
  }, [notebooks, showArchived])
  useEffect(() => {
    findNotebooks()
      .then(data => setNotebooks(data))
      .catch(e => {
        console.error('Error fetching notebooks: ', e)
        setErrorMessage('No se puede mostrar el listado')
      })
  }, [setErrorMessage])

  return (
    <>
      <Header text='Gestión' />
      <div className='common_toolbar'>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
        <FormControlLabel
          margin='dense'
          name='archived'
          control={
            <Checkbox
              checked={showArchived}
              onChange={e => setShowArchived(e.target.checked)}
            />
          }
          label='Ver archivados'
        />
        <Link to='/admin/notebooks/create'>
          <LoadingButton label='Nuevo cuadernillo' startIcon={<Add />} />
        </Link>
      </div>

      <InformationTable
        details={[
          {
            title: 'Nombre',
            key: 'name',
            align: 'left'
          },
          {
            title: 'Categoría',
            key: 'category',
            align: 'left'
          },
          {
            title: 'Subido por',
            key: 'createdBy'
          },
          {
            title: 'Actualizado por',
            key: 'updatedBy'
          },
          { title: 'Tamaño', key: 'size' },
          {
            title: 'Visible',
            key: 'visible',
            align: 'center'
          }
        ]}
        data={filteredList
          .filter(notebook => {
            if (!search) return true
            const validName = notebook.name
              .toLowerCase()
              .includes(search.toLowerCase())
            const validCategory = notebook.category
              .toLowerCase()
              .includes(search.toLowerCase())
            const validId = notebook.id
              .toLowerCase()
              .includes(search.toLowerCase())
            return validName || validCategory || validId
          })
          .map(notebook => ({
            category: notebook.category,
            name: (
              <Link
                to={`/admin/notebooks/${notebook.id}`}
                style={{ color: 'var(--success-color)', fontWeight: 'bold' }}
              >
                {notebook.name}
              </Link>
            ),
            createdBy: notebook.createdBy,
            updatedBy: notebook.updatedBy,
            size: (
              <Chip
                label={
                  notebook.size ? fileSize(notebook.size, { round: 0 }) : '-'
                }
                size='small'
              />
            ),
            visible: <StatusIcon active={notebook.visible} />
          }))}
      />
    </>
  )
}

export default NotebookList
