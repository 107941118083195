import React, { Component } from 'react'
import lupa from './lupa.svg'
import './SearchBar.css'

class SearchBar extends Component {
  handleChange = e => {
    this.setState({ searchFilter: e.target.value })
    this.props.onChange(e.target.value)
  }

  render() {
    return (
      <div className='SearchBar'>
        <input
          className='SearchBar-input'
          type='text'
          placeholder='Buscar...'
          value={this.props.search}
          onChange={this.handleChange}
        />
        <span className='SearchBar-bar' />
        <span className='SearchBar-icon'>
          <img src={lupa} alt='Lupa' />
        </span>
      </div>
    )
  }
}

export default SearchBar
