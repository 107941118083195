import CONFIG from '../config'
import { getAuthenticationToken } from '../utils'

const BASE_URL = `${CONFIG.apiUrl}/api/`
export const COMMON_ERROR_MESSAGE = 'Something went wrong ...'

const processResponse = async response => {
  const JSONResponse = await response.json()
  if (response.ok) return JSONResponse.body
  if (JSONResponse.message?.includes('not active')) {
    localStorage.removeItem(CONFIG.accessTokenKey)
    window.location.reload()
  }
  throw new Error(JSONResponse.message || COMMON_ERROR_MESSAGE)
}
function makeHeaders(useAuth = false, useAcceptAPP_JSON = false) {
  return useAuth
    ? {
        ...(useAcceptAPP_JSON
          ? { Accept: 'application/json' }
          : { 'Content-Type': 'application/json' }),
        Authorization: `Bearer ${getAuthenticationToken()}`
      }
    : {
        ...(useAcceptAPP_JSON
          ? { Accept: 'application/json' }
          : { 'Content-Type': 'application/json' })
      }
}
export function GET({ url = '', useAuth = true }) {
  return fetch(BASE_URL + url, {
    method: 'GET',
    headers: makeHeaders(useAuth)
  }).then(async response => {
    return await processResponse(response)
  })
}
export function POST({
  url = '',
  data = {},
  useAuth = true,
  useAcceptAPP_JSON = false
}) {
  return fetch(BASE_URL + url, {
    method: 'POST',
    headers: makeHeaders(useAuth, useAcceptAPP_JSON),
    body: useAcceptAPP_JSON ? data : JSON.stringify(data)
  }).then(async response => {
    return await processResponse(response)
  })
}
export function PUT({
  url = '',
  data = {},
  useAuth = true,
  useAcceptAPP_JSON = false
}) {
  return fetch(BASE_URL + url, {
    method: 'PUT',
    headers: makeHeaders(useAuth, useAcceptAPP_JSON),
    body: useAcceptAPP_JSON ? data : JSON.stringify(data)
  }).then(async response => {
    return await processResponse(response)
  })
}
