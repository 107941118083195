export const drawLine = (canvas, x0, y0, x1, y1, color, lineWidth) => {
  if (!canvas || !canvas.current) return false
  const context = canvas.current.getContext('2d', { willReadFrequently: true })
  context.beginPath()
  context.moveTo(x0, y0)
  context.lineTo(x1, y1)
  if (color === 'transparent')
    context.globalCompositeOperation = 'destination-out'
  else {
    context.globalCompositeOperation = 'source-over'
    context.strokeStyle = color
  }
  context.lineWidth = lineWidth
  context.stroke()
  context.closePath()
  return true
}

export const clear = canvas => {
  if (!canvas || !canvas.current) return false
  canvas.current
    .getContext('2d', { willReadFrequently: true })
    .clearRect(0, 0, canvas.current.width, canvas.current.height)
}

export const handleDownload = canvas => {
  const fauxCanvas = document.createElement('canvas')
  fauxCanvas.width = canvas.current.width
  fauxCanvas.height = canvas.current.height
  const fauxContext = fauxCanvas.getContext('2d', { willReadFrequently: true })
  fauxContext.fillStyle = '#fff'
  fauxContext.fillRect(0, 0, canvas.current.width, canvas.current.height)
  fauxContext.drawImage(canvas.current, 0, 0)
  const data = fauxCanvas.toDataURL('image/jpeg')
  const fauxLink = document.createElement('a')
  fauxLink.href = data
  fauxLink.download = 'board.jpg'
  document.body.appendChild(fauxLink)
  fauxLink.click()
  document.body.removeChild(fauxLink)
}
