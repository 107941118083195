import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ExamForm from '../ui/ExamForm'
import Header from '../../../../components/Header/Header'
import { createExam } from '../../../../api/exams'
import { useMessageSnackbarActions } from '../../../../context/MessageProvider'
import '../Common.css'

function ExamCreate() {
  const navigate = useNavigate()
  const [isSaving, setIsSaving] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const handleSave = newExam => {
      setIsSaving(true)
      createExam(newExam)
        .then(() => {
          setIsSaving(false)
          navigate('/admin/exams')
          setSuccessMessage()
        })
        .catch(e => {
          setIsSaving(false)
          console.error('Error creating exam: ', e)
          setErrorMessage(e.message)
        })
    }

  return (
    <>
      <Header text='Gestión' />
      <div className='adjust_form'>
        <ExamForm
          title='Añadir examen'
          onSubmit={handleSave}
          isSaving={isSaving}
        />
      </div>
    </>
  )
}

export default ExamCreate
