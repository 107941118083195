import { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Header from '../../components/Header/Header'
import Card from '../../components/Card/Card'
import { useVisibleData } from '../../context/BrainProvider'
import css from './Examenes.module.css'

const TabContent = ({ tabNumber, activeTab, children })=> {
  if (tabNumber !== activeTab) return null
  return children
}

const Examenes = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { exams } = useVisibleData()

  const handleChange = (_, newValue) => setActiveTab(newValue)

  return (
    <>
      <Header text='Exámenes' />
      <Tabs variant='fullWidth' value={activeTab} onChange={handleChange}>
        <Tab label='Exámenes Resueltos' />
        <Tab label='Exámenes Sin Resolver' />
      </Tabs>
      <TabContent tabNumber={0} activeTab={activeTab}>
        <div className={css.examenes}>
          {exams
            .filter(exam => !!exam.solved)
            .map(exam => exam.type)
            .filter((type, index, types) => types.indexOf(type) === index)
            .map(type => (
              <Card
                key={type}
                link={`/examenes/${type}/`}
                color='black'
                content={type}
              />
            ))}
        </div>
      </TabContent>
      <TabContent tabNumber={1} activeTab={activeTab}>
        <div className={css.examenes}>
          {exams
            .filter(exam => !exam.solved)
            .map(exam => exam.type)
            .filter((type, index, types) => types.indexOf(type) === index)
            .map(type => (
              <Card
                key={type}
                link={`/examenes/${type}/`}
                color='black'
                content={type}
              />
            ))}
        </div>
      </TabContent>
    </>
  )
}

export default Examenes
