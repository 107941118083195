import React from 'react'
import Header from '../../components/Header/Header'
import Card from '../../components/Card/Card'
import { useVisibleData } from '../../context/BrainProvider'
import colors from '../../data/colors'

const Video = () => {
  const { videos } = useVisibleData()
  return (
    <div style={{ display: 'grid' }}>
      <Header text='Videos' />
      <div
        style={{
          display: 'grid',
          margin: '20px',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridGap: '20px'
        }}
      >
        {videos
          .map(video => video.category)
          .filter(
            (category, index, categories) =>
              categories.indexOf(category) === index
          )
          .map(category => (
            <Card
              key={category}
              link={`/videos/${category.toLowerCase()}`}
              color={
                colors.find(
                  color => color.name.toLowerCase() === category.toLowerCase()
                )?.color
              }
              content={category}
            />
          ))}
      </div>
    </div>
  )
}

export default Video
