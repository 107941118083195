import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { assoc } from 'ramda'
import VideoForm from '../ui/VideoForm'
import Spinner from '../../../../components/shared/Spinner'
import Header from '../../../../components/Header/Header'
import { getVideo, updateVideo } from '../../../../api/videos'
import { useMessageSnackbarActions } from '../../../../context/MessageProvider'
import '../Common.css'

const initialState = {
  video: null,
  isLoading: true,
  error: false,
  isSaving: false,
  activeTab: 0
}
function VideoEdit() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [state, setState] = useState(initialState)
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const handleSave = updatedVideo => {
    setState(assoc('isSaving', true))
    updateVideo(id, updatedVideo)
      .then(() => {
        setState(assoc('isSaving', false))
        navigate('/admin/videos')
        setSuccessMessage()
      })
      .catch(e => {
        setState(assoc('isSaving', false))
        console.error('Error updating video: ', e)
        setErrorMessage(e.message)
      })
  }

  useEffect(() => {
    if (!id) return
    getVideo(id)
      .then(video => setState({ ...initialState, video, isLoading: false }))
      .catch(e => {
        console.error('Error getting video: ', e)
        setErrorMessage('Error al obtener el video')
        setState({ ...initialState, isLoading: false, error: true })
      })
  }, [id, setErrorMessage])

  if (state.error) return <p>Se ha producido un error</p>
  if (!state.video || state.isLoading) return <Spinner />
  return (
    <>
      <Header text='Gestión' />
      <div className='adjust_form'>
        <VideoForm
          title={'Editar video'}
          // @ts-ignore
          video={state.video}
          isSaving={state.isSaving}
          onSubmit={handleSave}
          isEditMode
        />
      </div>
    </>
  )
}

export default VideoEdit
