import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ThemeProvider } from '@mui/material/styles'
import { StyledEngineProvider } from '@mui/material/styles'
import AuthProvider from './context/AuthProvider'
import MessageProvider from './context/MessageProvider'
import { NotificationManager } from './context/NotificationManager/NotificationManager'
import BrainProvider from './context/BrainProvider'
import config from './config'
import theme from './config/theme'
import App from './App'
import './index.css'

const app = (
  <React.StrictMode>
    <>
      <CssBaseline />
      <GoogleOAuthProvider clientId={config.google.clientId}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <NotificationManager>
                <MessageProvider>
                  <AuthProvider>
                    <BrainProvider>
                      <App />
                    </BrainProvider>
                  </AuthProvider>
                </MessageProvider>
              </NotificationManager>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  </React.StrictMode>
)

createRoot(document.getElementById('root')).render(app)
