import React from 'react'
import { Link } from 'react-router-dom'

const VideoCard = ({ id, name }) => (
  <Link to={`/video/${id}`} className='video'>
    <span className='title'>
      <span className='text'>{name}</span>
    </span>
    <span className='play'>&#9654;</span>
  </Link>
)

export default VideoCard
