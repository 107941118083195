import React, { useContext, useMemo, useState } from 'react'

export const MessageContext = React.createContext({
  message: '',
  type: ''
})

export const MessageActionsContext = React.createContext({
  clear: () => {},
  setErrorMessage: (msg = '') => {},
  setSuccessMessage: (msg = '') => {},
  setInfoMessage: (msg = '') => {},
  setWarningMessage: (msg = '') => {}
})

const MessageProvider = ({ children }) => {
  const [message, setMessage] = useState('')
  const [type, setType] = useState('info')

  const update = ({ message, type }) => {
    setMessage(message)
    setType(type)
  }

  const value = useMemo(
    () => ({
      clear: () => update({ message: '', type: 'info' }),
      setErrorMessage: (message = 'Se ha producido un error') =>
        update({ message, type: 'error' }),
      setSuccessMessage: (message = 'Guardado correctamente') =>
        update({ message, type: 'success' }),
      setInfoMessage: message => update({ message, type: 'info' }),
      setWarningMessage: message => update({ message, type: 'warning' })
    }),
    []
  )

  return (
    <MessageContext.Provider value={{ message, type }}>
      <MessageActionsContext.Provider value={value}>
        {children}
      </MessageActionsContext.Provider>
    </MessageContext.Provider>
  )
}

export default MessageProvider

export const useMessageSnackbarActions = () => useContext(MessageActionsContext)
export const useMessageSnackbar = () => useContext(MessageContext)
