import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Add } from '@mui/icons-material'
import { isEmpty } from 'ramda'
import { Chip } from '@mui/material'
import fileSize from 'filesize'
import {
  createTrainingItem,
  findTrainingItemsByTeacher,
  updateTrainingItem
} from '../../../../../api/training'
import { useMessageSnackbarActions } from '../../../../../context/MessageProvider'
import InformationTable from '../../../../../components/InformationTable/InformationTable'
import SearchInput from '../../../../../components/SearchInput/SearchInput'
import LoadingButton from '../../../../../components/shared/LoadingButton'
import StatusIcon from '../../../../../components/shared/StatusIcon'
import ConfirmModal from '../../../../../components/shared/ConfirmModal'
import TrainingForm from './TrainingForm'

function Training({ teacherId }) {
  const [items, setItems] = useState([])
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const filteredData = useMemo(() => {
    if (!search) return items
    return items.filter(item => {
      const validName = item.name.toLowerCase().includes(search.toLowerCase())
      const validId = item.id.toLowerCase().includes(search.toLowerCase())
      const validCategory = item.category
        .toLowerCase()
        .includes(search.toLowerCase())
      return validName || validId || validCategory
    })
  }, [items, search])

  const fetchData = useCallback(() => {
    findTrainingItemsByTeacher(teacherId)
      .then(setItems)
      .catch(() => setErrorMessage('No se puede mostrar el listado'))
  }, [setErrorMessage, teacherId])
  const handleEditItem = useCallback(
    data => {
      setIsSaving(true)
      updateTrainingItem(selectedItem.id, {
        ...selectedItem,
        ...data,
        teacherId
      })
        .then(() => {
          setSuccessMessage()
          fetchData()
          setShowModal(false)
        })
        .catch(e => {
          if (e.message.includes('Already exists'))
            setErrorMessage(
              `Ya existe el archivo (${
                e.message.split(': ')[1]
              }) para este usuario`
            )
          else if (e.message.includes('same file'))
            setErrorMessage('Estás subiendo el mismo archivo')
          else setErrorMessage('No se ha podido guardar')
        })
        .finally(() => setIsSaving(false))
    },
    [fetchData, selectedItem, setErrorMessage, setSuccessMessage, teacherId]
  )
  const handleCreateItem = useCallback(
    data => {
      setIsSaving(true)
      createTrainingItem({ ...data, teacherId })
        .then(() => {
          setSuccessMessage()
          fetchData()
          setShowModal(false)
        })
        .catch(e => {
          if (e.message.includes('Already exists'))
            setErrorMessage(
              `Ya existe el archivo (${
                e.message.split(': ')[1]
              }) para este usuario`
            )
          else setErrorMessage('No se ha podido guardar')
        })
        .finally(() => setIsSaving(false))
    },
    [fetchData, setErrorMessage, setSuccessMessage, teacherId]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const isEditMode = selectedItem && !isEmpty(selectedItem)
  return (
    <div>
      <div className='common_toolbar'>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
        <LoadingButton
          label='Nueva formación'
          startIcon={<Add />}
          onClick={() => {
            setShowModal(true)
            setSelectedItem({})
          }}
        />
      </div>
      <InformationTable
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },

          {
            title: 'Categoría',
            key: 'category'
          },
          {
            title: 'Tamaño',
            key: 'size'
          },
          {
            title: 'Visible',
            key: 'visible'
          }
        ]}
        data={filteredData.map(item => ({
          name: (
            <span
              style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
              onClick={() => {
                setShowModal(true)
                setSelectedItem(item)
              }}
            >
              {item.name}
            </span>
          ),
          category: item.category,
          size: (
            <Chip
              label={item.size ? fileSize(item.size, { round: 0 }) : '-'}
              size='small'
            />
          ),
          visible: <StatusIcon active={item.visible} />
        }))}
      />
      {showModal && (
        <ConfirmModal
          title={isEditMode ? 'Editar ' + selectedItem.name : 'Nueva formación'}
          subtitle=''
          children={
            <TrainingForm
              item={selectedItem}
              isEditMode={isEditMode}
              isSaving={isSaving}
              onCancel={() => {
                setShowModal(false)
                setSelectedItem({})
              }}
              onSubmit={isEditMode ? handleEditItem : handleCreateItem}
            />
          }
          showActions={false}
          showModal
        />
      )}
    </div>
  )
}

export default Training
