import { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Header from '../../components/Header/Header'
import PortadaCuadernillo from '../../components/PortadaCuadernillo/PortadaCuadernillo'
import SearchBar from '../../components/SearchBar/SearchBar'
import { useVisibleData } from '../../context/BrainProvider'
import { useParams } from 'react-router-dom'
import './Asignatura.css'

const Asignatura = ()=> {

  const [activeTab, setActiveTab] = useState(0)
  const [search, setSearch] = useState('')

  const { notebooks } = useVisibleData()

  const { id } = useParams();

  const handleChange = (_, tabIndex) => setActiveTab(tabIndex)

  return (
    <>
      <Header text={id} />
      <div className='Asignaturas-toolbar'>
        <Tabs
          variant='fullWidth'
          value={activeTab}
          onChange={handleChange}
        >
          <Tab label='Cuadernillos' />
        </Tabs>
        <SearchBar search={search} onChange={setSearch} />
      </div>

      {activeTab === 0 && (
        <div className='Cuadernillos'>
          <ul>
            {notebooks
              .filter(notebook => notebook.category.toLowerCase() === id)
              .filter(notebook => notebook.name.toLowerCase().includes(search.toLowerCase()))
              .map(notebook => (
                <PortadaCuadernillo
                  key={notebook.id}
                  id={notebook.id}
                  name={notebook.name}
                  category={notebook.category}
                />
              ))}
          </ul>
        </div>
      )}
    </>
  )
}

export default Asignatura
