import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { findVisibleNotebooks } from '../api/notebooks'
import { findVisibleExams } from '../api/exams'
import { findVisibleVideos } from '../api/videos'
import { useMessageSnackbarActions } from './MessageProvider'

const BrainContext = createContext({})

function BrainProvider({ children }) {
  const [notebooks, setNotebooks] = useState([])
  const [exams, setExams] = useState([])
  const [videos, setVideos] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const { setErrorMessage } = useMessageSnackbarActions()

  const fetchData = useCallback(async () => {
    try {
      setIsFetching(true)
      await findVisibleExams().then(setExams)
      await findVisibleNotebooks().then(setNotebooks)
      await findVisibleVideos().then(setVideos)
    } catch (error) {
      console.error(
        '[NotebookProvider]:Error getting visible data: ',
        error.message
      )
      setErrorMessage('Se ha producido un error listando el contenido')
    } finally {
      setIsFetching(false)
    }
  }, [setErrorMessage])
  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <BrainContext.Provider
      value={{ notebooks, exams, videos, isFetching, fetchData }}
    >
      {children}
    </BrainContext.Provider>
  )
}

export default BrainProvider

/**
 *
 * @returns {{
 * notebooks: object[]
 * exams: object[]
 * videos: object[]
 * isFetching: boolean
 * fetchData: () => Promise<void>
 * }}
 */
export const useVisibleData = () => useContext(BrainContext)
