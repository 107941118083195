import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  assocPath,
  dissocPath,
  isEmpty,
  pathOr,
  path,
  hasPath,
  take,
  equals,
  any,
  is
} from 'ramda'
import { makeCustomChangeEvent, makeCustomCheckChangeEvent } from './lib'
/**
 * This hook provides a methods to manage forms
 * @param {array} requiredFieldPaths strings that can contain dots
 */
export default function useFormManager(requiredFieldPaths = [], data = {}) {
  const [form, setForm] = useState(data)
  const [requiredFields, setRequiredFields] = useState({})
  const [requiredPaths, setRequiredPaths] = useState(requiredFieldPaths)
  const handleFormChange = useCallback(e => {
    const { path, value } = makeCustomChangeEvent(e)
    setForm(assocPath(path, value))
  }, [])

  const handleCheckChange = useCallback(e => {
    const { path, checked } = makeCustomCheckChangeEvent(e)
    setForm(assocPath(path, checked))
  }, [])

  const handleDateChange = useCallback(
    (path, value) => {
      handleFormChange({ target: { name: path, value } })
    },
    [handleFormChange]
  )

  const handleUploadFile = useCallback(
    e => {
      const { name, files } = e.target
      handleFormChange({ target: { name, value: files } })
    },
    [handleFormChange]
  )

  const checkRequiredFields = useCallback(() => {
    requiredPaths.forEach((requiredField = '') => {
      const targetPath = Array.isArray(requiredField)
        ? requiredField
        : requiredField.split('.')
      const value = pathOr(null, targetPath, form)
      if (!value) setRequiredFields(assocPath(targetPath, 'Requerido'))
      else {
        setRequiredFields(state => {
          if (isEmpty(state)) return state
          let updatedRequiredFields = dissocPath(targetPath, state)
          for (let index = targetPath.length; index > -1; index--) {
            let pathToCheck = take(index - 1, targetPath)
            if (
              hasPath(pathToCheck, state) &&
              isEmpty(path(pathToCheck, state))
            )
              updatedRequiredFields = dissocPath(
                pathToCheck,
                updatedRequiredFields
              )
          }
          return updatedRequiredFields
        })
      }
    })
  }, [requiredPaths, form])

  const getErrorPropsByRequiredField = useCallback(
    (key = '') => {
      const path = Array.isArray(key) ? key : key.split('.')
      const value = pathOr(null, path, requiredFields)
      if (!value) return { required: true }
      return {
        error: true,
        helperText: value,
        required: true
      }
    },
    [requiredFields]
  )

  const clearForm = useCallback(() => {
    setForm({})
    setRequiredFields({})
  }, [])

  const clearRequiredFields = useCallback((requiredFieldPaths = []) => {
    setRequiredFields({})
    setRequiredPaths(requiredFieldPaths)
  }, [])
  const hasPendingRequiredFields = useMemo(() => {
    const aux = []
    const recursiveFn = data => {
      for (const key in data) {
        const value = data[key]
        if (Array.isArray(value)) {
          value.map(recursiveFn)
        }
        if (is(Object, value)) {
          if (!isEmpty(value)) recursiveFn(value)
        } else {
          aux.push(true)
          return
        }
      }
    }
    recursiveFn(requiredFields)
    return any(equals(true), aux)
  }, [requiredFields])

  useEffect(() => {
    checkRequiredFields()
  }, [checkRequiredFields])
  return {
    checkRequiredFields,
    hasPendingRequiredFields,
    getErrorPropsByRequiredField,
    form,
    handleFormChange,
    handleDateChange,
    handleCheckChange,
    handleUploadFile,
    clearForm,
    clearRequiredFields,
    setForm
  }
}
