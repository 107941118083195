import React from 'react'
import { Button, Chip, TextField } from '@mui/material'
import styles from './TagList.module.css'

function TagList({
  tags = [],
  inputTagValue = '',
  onTagDelete,
  onTagAdd,
  onChange
}) {
  return (
    <div className={styles.tagSection}>
      {tags.map((tag, i) => (
        <Chip
          key={i}
          label={tag}
          onDelete={() => onTagDelete(tag)}
          sx={{ m: 1 }}
        />
      ))}
      <div className={styles.tagsActions}>
        <TextField
          value={inputTagValue}
          onChange={onChange}
          placeholder='Agregar etiqueta'
          onKeyDown={onTagAdd}
          size='small'
          helperText='Puedes pulsar enter para agregar'
        />
        <Button onClick={() => onTagAdd({ key: 'Enter' })}>Agregar</Button>
      </div>
    </div>
  )
}

export default TagList
