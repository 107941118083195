import React from 'react'
import { Link } from 'react-router-dom'
import { Divider, IconButton, Menu, MenuItem } from '@mui/material'
import { Search } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVertOutlined'
import { useAuth } from '../../context/AuthProvider'
import useBreakPoint from '../../hooks/useBreakPoint'
import styles from './MainMenu.module.css'

function MainMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { handleLogout, isAdmin } = useAuth()
  const { isMobile } = useBreakPoint()
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        color='primary'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {isMobile && [
          <Link key='notebooks' to='/'>
            <MenuItem>Cuadernillos</MenuItem>
          </Link>,
          <Link key='exams' to='/examenes'>
            <MenuItem>Exámenes</MenuItem>
          </Link>,
          <Link key='videos' to='/videos'>
            <MenuItem>Videos</MenuItem>
          </Link>,
          <Divider key='divider-admin-1' sx={{ my: 0.5 }} />
        ]}
        {isAdmin && [
          <Link
            key='admin-users'
            className={styles.adminColor}
            to='/admin/users'
          >
            <MenuItem>Users</MenuItem>
          </Link>,
          <Link
            key='admin-notebooks'
            className={styles.adminColor}
            to='/admin/notebooks'
          >
            <MenuItem>Notebooks</MenuItem>
          </Link>,
          <Link
            key='admin-exams'
            className={styles.adminColor}
            to='/admin/exams'
          >
            <MenuItem>Exams</MenuItem>
          </Link>,
          <Link
            key='admin-videos'
            className={styles.adminColor}
            to='/admin/videos'
          >
            <MenuItem>Videos</MenuItem>
          </Link>
        ]}
        {/* MI PERFIL: PARA VER MIS FORMACIONES */}
        {!isAdmin && (
          <Link key='account' className={styles.accountColor} to='/account'>
            <MenuItem>Perfil</MenuItem>
          </Link>
        )}
        <Divider key='divider-admin-2' sx={{ my: 0.5 }} />
        <Link key='search' to='/global-search'>
          <MenuItem>
            <span className={styles.globalSearch}>
              Buscar <Search />
            </span>
          </MenuItem>
        </Link>
        <Divider key='divider-admin-3' sx={{ my: 0.5 }} />
        <MenuItem onClick={handleLogout}>
          <span className={styles.logout}>Cerrar sesión</span>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default MainMenu
