import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { assoc } from 'ramda'
import NotebookForm from '../ui/NotebookForm'
import Spinner from '../../../../components/shared/Spinner'
import Header from '../../../../components/Header/Header'
import { getNotebook, updateNotebook } from '../../../../api/notebooks'
import { useMessageSnackbarActions } from '../../../../context/MessageProvider'
import '../Common.css'

const initialState = {
  notebook: null,
  isLoading: true,
  error: false,
  isSaving: false
}

function NotebookEdit() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [state, setState] = useState(initialState)
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const handleSave = updatedNotebook => {
    setState(assoc('isSaving', true))
    updateNotebook(id, updatedNotebook)
      .then(() => {
        setState(assoc('isSaving', false))
        navigate('/admin/notebooks')
        setSuccessMessage()
      })
      .catch(e => {
        setState(assoc('isSaving', false))
        console.error('Error updating notebook: ', e)
        setErrorMessage('No se ha podido actualizar')
      })
    }

  useEffect(() => {
    if (!id) return
    getNotebook(id)
      .then(notebook =>
        setState({ ...initialState, notebook, isLoading: false })
      )
      .catch(e => {
        console.error('Error getting notebook: ' + e)
        setErrorMessage('Error al obtener el notebook')
        setState({ ...initialState, isLoading: false, error: true })
      })
  }, [id, setErrorMessage])

  if (state.error) return <p>Se ha producido un error</p>
  if (!state.notebook || state.isLoading) return <Spinner />
  return (
    <>
      <Header text='Gestión' />
      <div className='adjust_form'>
        <NotebookForm
          title='Editar cuadernillo'
          notebook={state.notebook}
          onSubmit={handleSave}
          isEditMode
          isSaving={state.isSaving}
        />
      </div>
    </>
  )
}

export default NotebookEdit
