import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { propEq, sortBy } from 'ramda'
import { Link } from 'react-router-dom'
import { Checkbox, FormControlLabel } from '@mui/material'
import Header from '../../../../components/Header/Header'
import SearchInput from '../../../../components/SearchInput/SearchInput'
import InformationTable from '../../../../components/InformationTable/InformationTable'
import { dateToString } from '../../../../utils/date'
import { useMessageSnackbarActions } from '../../../../context/MessageProvider'
import StatusIcon from '../../../../components/shared/StatusIcon'
import { findUsers } from '../../../../api/users'
import { ROLES } from '../../../../utils/constants'
import { useAuth } from '../../../../context/AuthProvider'

function UserList() {
  const { user } = useAuth()
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')
  const [showAdmins, setShowAdmins] = useState(false)
  const { setErrorMessage } = useMessageSnackbarActions()

  const filteredData = useMemo(() => {
    let targetUsers = users.filter(user => {
      if (showAdmins) return user.role === ROLES.admin
      return user.role !== ROLES.admin
    })
    if (!search) return targetUsers
    return targetUsers.filter(user => {
      const validName = user.name.toLowerCase().includes(search.toLowerCase())
      const validEmail = user.email.toLowerCase().includes(search.toLowerCase())
      return validName || validEmail
    })
  }, [users, search, showAdmins])

  const fetchData = useCallback(() => {
    findUsers()
      .then(sortBy(propEq('active', true)))
      .then(setUsers)
      .catch(() => setErrorMessage('No se puede mostrar el listado'))
  }, [setErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <>
      <Header text={`Perfil (${user?.email})`} />
      <div className='common_toolbar'>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
        <FormControlLabel
          margin='dense'
          control={
            <Checkbox
              checked={showAdmins}
              onChange={e => setShowAdmins(e.target.checked)}
            />
          }
          label='Ver admins'
        />
      </div>
      <InformationTable
        details={[
          { title: 'Nombre usuario', key: 'name', align: 'left' },
          {
            title: 'Email',
            key: 'email'
          },
          { title: 'Role', key: 'role' },
          { title: 'Último Login', key: 'lastLogin', sortDisabled: true },
          {
            title: 'Acceso',
            key: 'active',
            sortDisabled: true,
            align: 'center'
          }
        ]}
        data={filteredData.map(user => ({
          email: user.email,
          name: (
            <Link
              to={`/admin/users/${user.id}`}
              style={{ color: 'var(--success-color)', fontWeight: 'bold' }}
            >
              {user.name}
            </Link>
          ),
          lastLogin: user.lastSuccessfullLogin
            ? dateToString(user.lastSuccessfullLogin)
            : '--',
          role: <p>{user.role}</p>,
          active: <StatusIcon active={user.active} />
        }))}
      />
    </>
  )
}

export default UserList
