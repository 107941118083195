import React, { useCallback } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  TextField
} from '@mui/material'
import { Save } from '@mui/icons-material'
import useFormManager from '../../../../hooks/useFormManager'
import { useTags } from '../../../../hooks/useTags'
import LoadingButton from '../../../../components/shared/LoadingButton'
import CategorySelect from '../../../../components/shared/CategorySelect'
import TagList from '../../../../components/shared/TagList'

const REQUIRED_FIELDS = ['name', 'url', 'category']
const DESCRIPTION_LIMIT = 1200

function VideoForm({
  video = {},
  title = '',
  isSaving = false,
  isEditMode = false,
  onSubmit
}) {
  const {
    handleFormChange,
    handleCheckChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    form
  } = useFormManager(REQUIRED_FIELDS, video)
  const {
    tags,
    inputTagValue,
    handleInputTagValueChange,
    handleAddTag,
    handleDeleteTag
  } = useTags(video.tags)

  const handleDescriptionChange = useCallback(
    e => {
      if (e.target.value.length > DESCRIPTION_LIMIT) return
      handleFormChange(e)
    },
    [handleFormChange]
  )
  const handleSubmit = useCallback(() => {
    onSubmit({ ...form, tags })
  }, [form, onSubmit, tags])
  return (
    <Card className='bordered_shadow' variant='outlined'>
      <CardHeader title={title} />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '10px'
        }}
      >
        <TextField
          name='name'
          margin='dense'
          label='Nombre'
          variant='standard'
          value={form.name || ''}
          onChange={handleFormChange}
          fullWidth
          {...getErrorPropsByRequiredField('name')}
        />
        <CategorySelect
          name='category'
          margin='dense'
          label='Categoría'
          variant='standard'
          value={form.category || ''}
          onChange={handleFormChange}
          fullWidth
          {...getErrorPropsByRequiredField('category')}
        />
        <TextField
          name='url'
          margin='dense'
          label='URL'
          variant='standard'
          value={form.url || 'https://www.youtube.com/embed/'}
          onChange={handleFormChange}
          fullWidth
          {...getErrorPropsByRequiredField('url')}
        />
        <TextField
          name='description'
          margin='dense'
          label='Descripción'
          variant='standard'
          value={form.description || ''}
          onChange={handleDescriptionChange}
          maxRows={6}
          helperText={`${form.description?.length || 0}/1200`}
          multiline
          fullWidth
        />
        <div>
          <FormControlLabel
            margin='dense'
            name='visible'
            control={
              <Checkbox
                checked={form.visible || false}
                onChange={handleCheckChange}
              />
            }
            label='Activo'
          />
        </div>
        <TagList
          tags={tags}
          inputTagValue={inputTagValue}
          onTagDelete={handleDeleteTag}
          onChange={handleInputTagValueChange}
          onTagAdd={handleAddTag}
        />
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <LoadingButton
          label='Guardar'
          onClick={handleSubmit}
          isLoading={isSaving}
          disabled={hasPendingRequiredFields}
          startIcon={<Save />}
        />
      </CardActions>
    </Card>
  )
}

export default VideoForm
