import React, { useCallback, useRef } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField
} from '@mui/material'
import { Cancel, Save } from '@mui/icons-material'
import LoadingButton from '../../../../../components/shared/LoadingButton'
import useFormManager from '../../../../../hooks/useFormManager'
import CategorySelect from '../../../../../components/shared/CategorySelect'

const REQUIRED_FIELDS = ['name', 'category', 'file']
const REQUIRED_FIELDS_EDIT = ['name', 'category']

function TrainingForm({
  item = {},
  isEditMode = false,
  isSaving = false,
  onCancel,
  onSubmit
}) {
  const ref = useRef()
  const {
    handleFormChange,
    handleUploadFile,
    handleCheckChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    form
  } = useFormManager(isEditMode ? REQUIRED_FIELDS_EDIT : REQUIRED_FIELDS, item)

  const handleDeleteFile = useCallback(() => {
    handleFormChange({ target: { name: 'file', value: null } })
    handleFormChange({ target: { name: 'filename', value: null } })
  }, [handleFormChange])

  const handleSubmit = useCallback(() => {
    onSubmit(form)
  }, [form, onSubmit])
  return (
    <Card elevation={0}>
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '10px'
        }}
      >
        <TextField
          name='name'
          margin='dense'
          label='Nombre'
          variant='standard'
          value={form.name || ''}
          onChange={handleFormChange}
          fullWidth
          {...getErrorPropsByRequiredField('name')}
        />
        <CategorySelect
          name='category'
          value={form.category || ''}
          onChange={handleFormChange}
          fullWidth
          {...getErrorPropsByRequiredField('category')}
        />
        <div style={{ marginTop: 10 }}>
          {form.file ? (
            <Chip label={form.file.name} onDelete={handleDeleteFile} />
          ) : isEditMode && form.filename ? (
            <Chip
              label={decodeURI(form.filename)}
              onDelete={handleDeleteFile}
            />
          ) : (
            <Button
              color='secondary'
              onClick={() =>
                ref.current.click()
              }
              variant='contained'
            >
              Seleccionar archivo
              <input
                name='file'
                type='file'
                ref={ref}
                hidden
                onChange={e =>
                  handleUploadFile({
                    target: { name: e.target.name, files: e.target.files[0] }
                  })
                }
                accept='application/pdf'
              />
            </Button>
          )}
        </div>
        <FormControlLabel
          margin='dense'
          name='visible'
          control={
            <Checkbox
              checked={form.visible || false}
              onChange={handleCheckChange}
            />
          }
          label='Activo'
        />
        {isEditMode && (
          <a target='_blank' href={form.url} rel='noreferrer'>
            <Button style={{ marginTop: 20, width: '100%' }} color='primary'>
              Ver archivo actual
            </Button>
          </a>
        )}
        <CardActions
          style={{
            justifyContent: 'flex-end'
          }}
        >
          <LoadingButton
            label='Cancelar'
            onClick={onCancel}
            startIcon={<Cancel />}
            variant='text'
            color='secondary'
          />
          <LoadingButton
            label='Guardar'
            onClick={handleSubmit}
            isLoading={isSaving}
            disabled={hasPendingRequiredFields}
            startIcon={<Save />}
            variant='text'
          />
        </CardActions>
      </CardContent>
    </Card>
  )
}

export default TrainingForm
