import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { Alert } from '@mui/material'
import * as PdfJsLib from 'pdfjs-dist/legacy/build/pdf.js'
import PdfJsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry'
import { clear } from '../../utils/canvas'
import Spinner from './Spinner'
import LoadingButton from './LoadingButton'
import styles from './DocumentViewer.module.css'
PdfJsLib.GlobalWorkerOptions.workerSrc = PdfJsWorker

function DocumentViewer({ url }) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pdfIsRendering, setPdfIsRendering] = useState(false)
  const [isCanvasReady, setIsCanvasReady] = useState(false)
  const [notebookPdf, setNotebookPdf] = useState({ source: null, page: 1 })
  const [pageNumPending, setPageNumPending] = useState(null)
  const [search, setSearch] = useState('')
  const notebookCanvasRef = useRef(null)

  const renderDocument = useCallback(
    page => {
      if (!notebookPdf.source || !isCanvasReady) return
      setPdfIsRendering(true)
      clear(notebookCanvasRef)
      notebookPdf.source
        .getPage(page)
        .then(async page => {
          const defaultViewport = page.getViewport({ scale: 1 })

          const blackboard = notebookCanvasRef.current
          const canvasContext = blackboard.getContext('2d', {
            willReadFrequently: true
          })
          const { devicePixelRatio = 1 } = window
          const dpr = Math.min(2, devicePixelRatio)
          const widthRatio =
            blackboard.clientWidth / (defaultViewport.width / dpr)
          const heightRatio =
            blackboard.clientHeight / (defaultViewport.height / dpr)
          const scale = Math.max(widthRatio, heightRatio)

          notebookCanvasRef.current.width = Math.floor(
            defaultViewport.width * scale
          )
          notebookCanvasRef.current.height = Math.floor(
            defaultViewport.height * scale
          )
          const viewport = page.getViewport({
            scale
          })

          const render = page.render({ canvasContext, viewport })
          await render.promise.then(() => {
            setPdfIsRendering(false)
            if (pageNumPending !== null) {
              renderDocument(pageNumPending)
              setPageNumPending(null)
            }
          })
        })
        .catch(e => {
          console.error('Error rendering pdf: ', e)
        })
        .finally(() => {
          setPdfIsRendering(false)
        })
    },
    [isCanvasReady, notebookPdf.source, pageNumPending]
  )

  const handlePageChange = useCallback(
    newPage => {
      const pages = notebookPdf.source && notebookPdf.source.numPages
      if (!pages) return
      const page = Math.min(Math.max(1, notebookPdf.page + newPage), pages)
      setNotebookPdf(state => ({ ...state, page }))
      setSearch(page)
      if (pdfIsRendering) {
        setPageNumPending(page)
      } else renderDocument(page)
    },
    [notebookPdf, pdfIsRendering, renderDocument]
  )

  const handleSearchPage = useCallback(() => {
    if (!search) {
      setSearch('')
      setNotebookPdf(state => ({ ...state, page: 1 }))
      if (pdfIsRendering) {
        setPageNumPending(1)
      } else renderDocument(1)
      return
    }
    const targetPage = search && Number(search)
    if (targetPage && !isNaN(targetPage)) {
      const pages = notebookPdf.source && notebookPdf.source.numPages
      if (!pages) return
      const page = Math.min(Math.max(1, targetPage), pages)
      setSearch(page)
      setNotebookPdf(state => ({ ...state, page }))
      if (pdfIsRendering) {
        setPageNumPending(page)
      } else renderDocument(page)
    }
  }, [notebookPdf.source, pdfIsRendering, renderDocument, search])
  useLayoutEffect(() => {
    if (isCanvasReady) return
    notebookCanvasRef.current.width =
      notebookCanvasRef.current.getBoundingClientRect().width
    notebookCanvasRef.current.height =
      notebookCanvasRef.current.getBoundingClientRect().height
    setIsCanvasReady(true)
  }, [isCanvasReady, pdfIsRendering])

  useEffect(() => {
    if (!url) return
    setIsLoading(true)
    PdfJsLib.getDocument(url)
      .promise.then(source => setNotebookPdf({ source, page: 1 }))
      .catch(() => {
        setNotebookPdf({ source: null, page: 1 })
        setError(true)
      })
      .finally(() => setIsLoading(false))
  }, [url])
  useEffect(() => {
    renderDocument(1)
  }, [renderDocument])
  if (isLoading) return <Spinner />
  if (error)
    return (
      <div className={styles.error}>
        <Alert variant='outlined' severity='warning'>
          No se ha podido visualizar el archivo
        </Alert>
      </div>
    )
  return (
    <div className={styles.viewer}>
      <canvas ref={notebookCanvasRef} className={styles.canvas} />
      <div className={styles.actions}>
        <LoadingButton
          variant='outlined'
          onClick={() => handlePageChange(-1)}
          isLoading={pdfIsRendering}
          label='<'
          size='large'
        />
        <p className={styles.info}>
          Page{' '}
          <input
            className={styles.input}
            value={search}
            onChange={e => setSearch(e.target.value)}
            onBlur={handleSearchPage}
            type='number'
          />{' '}
          / {notebookPdf?.source?.numPages}
        </p>

        <LoadingButton
          variant='outlined'
          onClick={() => handlePageChange(1)}
          isLoading={pdfIsRendering}
          label='>'
          size='large'
        />
      </div>
    </div>
  )
}

export default DocumentViewer
