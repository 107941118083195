import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Alert, Snackbar } from '@mui/material'
import {
  useMessageSnackbar,
  useMessageSnackbarActions
} from './context/MessageProvider'
import { useAuth } from './context/AuthProvider'
import Home from './views/Home/Home'
import Asignatura from './views/Asignatura/Asignatura'
import Cuadernillo from './views/Cuadernillo/Cuadernillo'
import Examenes from './views/Examenes/Examenes'
import Login from './views/Login/Login'
import AsignaturasTipo from './views/AsignaturasTipo/AsignaturasTipo'
import ListaExamenes from './views/ListaExamenes/ListaExamenes'
import Examen from './views/Examen/Examen'
import Videos from './views/Videos/Videos'
import VideoAsignatura from './views/Videos/VideoAsignatura'
import Video from './views/Videos/Video'
import Account from './views/Account/Account'
import Viewer from './views/Account/Viewer'
import GlobalSearch from './views/GlobalSearch/GlobalSearch'

import NotebookList from './views/admin/Notebooks/List/NotebookList'
import NotebookCreate from './views/admin/Notebooks/Create/NotebookCreate'
import NotebookEdit from './views/admin/Notebooks/Edit/NotebookEdit'
import ExamCreate from './views/admin/Exams/Create/ExamCreate'
import ExamEdit from './views/admin/Exams/Edit/ExamEdit'
import ExamList from './views/admin/Exams/List/ExamList'
import UserList from './views/admin/Users/List/UserList'
import UserEdit from './views/admin/Users/Edit/UserEdit'
import VideoList from './views/admin/Videos/List/VideoList'
import VideoCreate from './views/admin/Videos/Create/VideoCreate'
import VideoEdit from './views/admin/Videos/Edit/VideoEdit'

const Authentication = ({ type, to, children }) => {
  const { isAdmin, isAuthenticated } = useAuth()

  const match =
    (type === 'public' && !isAuthenticated) ||
    (type === 'private' && isAuthenticated) ||
    (type === 'admin' && isAdmin)

  if (match) return children
  else return <Navigate to={to} />
}

const AdminContent = () => (
  <Routes>
    <Route path='/notebooks' element={<NotebookList />} />
    <Route path='/notebooks/create' element={<NotebookCreate />} />
    <Route path='/notebooks/:id' element={<NotebookEdit />} />
    <Route path='/exams' element={<ExamList />} />
    <Route path='/exams/create' element={<ExamCreate />} />
    <Route path='/exams/:id' element={<ExamEdit />} />
    <Route path='/users' element={<UserList />} />
    <Route path='/users/:id' element={<UserEdit />} />
    <Route path='/videos' element={<VideoList />} />
    <Route path='/videos/create' element={<VideoCreate />} />
    <Route path='/videos/:id' element={<VideoEdit />} />
  </Routes>
)

const Content = () => (
  <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/asignaturas/:id' element={<Asignatura />} />
    <Route path='/cuadernillos/:id' element={<Cuadernillo />} />
    <Route path='/examenes' element={<Examenes />} />
    <Route path='/examenes/:id' element={<AsignaturasTipo />} />
    <Route path='/examenes/:tipo/:id' element={<ListaExamenes />} />
    <Route path='/examen/:id' element={<Examen />} />
    <Route path='/videos' element={<Videos />} />
    <Route path='/videos/:tipo' element={<VideoAsignatura />} />
    <Route path='/video/:id' element={<Video />} />
    <Route path='/account' element={<Account />} />
    <Route path='/account/files/:id' element={<Viewer />} />
    <Route path='/global-search' element={<GlobalSearch />} />
  </Routes>
)

const App = () => {
  const snackbar = useMessageSnackbar()
  const snackbarActions = useMessageSnackbarActions()

  useEffect(() => {
    if (!snackbar || !snackbar.message) return
    let isCurrent = true
    setTimeout(() => {
      if (!isCurrent) return
      snackbarActions.clear()
    }, 5000)
    return () => (isCurrent = false)
  }, [snackbar, snackbarActions])

  return (
    <>
      <Routes>
        <Route
          path='/login'
          element={
            <Authentication type='public' to='/'>
              <Login />
            </Authentication>
          }
        />
        <Route
          path='/admin/*'
          element={
            <Authentication type='admin' to='/login'>
              <AdminContent />
            </Authentication>
          }
        />
        <Route
          path='*'
          element={
            <Authentication type='private' to='/login'>
              <Content />
            </Authentication>
          }
        />
      </Routes>
      <Snackbar
        open={!!snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert elevation={6} variant='filled' severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default App
