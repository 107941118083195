import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { useMessageSnackbarActions } from '../../../../context/MessageProvider'
import InformationTable from '../../../../components/InformationTable/InformationTable'
import LoadingButton from '../../../../components/shared/LoadingButton'
import SearchInput from '../../../../components/SearchInput/SearchInput'
import StatusIcon from '../../../../components/shared/StatusIcon'
import Header from '../../../../components/Header/Header'
import { findVideos } from '../../../../api/videos'

function VideoList() {
  const { setErrorMessage } = useMessageSnackbarActions()
  const [videos, setVideos] = useState([])
  const [search, setSearch] = useState('')

  const filteredList = useMemo(() => {
    if (!search) return videos
    return videos.filter(video => {
      const validName = video.name.toLowerCase().includes(search.toLowerCase())
      const validCategory = video.category
        .toLowerCase()
        .includes(search.toLowerCase())
      return validName || validCategory
    })
  }, [search, videos])
  useEffect(() => {
    findVideos()
      .then(data => setVideos(data))
      .catch(e => {
        console.error('Error fetching videos: ', e)
        setErrorMessage('No se puede mostrar el listado')
      })
  }, [setErrorMessage])

  return (
    <>
      <Header text='Gestión' />
      <div className='common_toolbar'>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
        <Link to='/admin/videos/create'>
          <LoadingButton label='Nuevo video' startIcon={<Add />} />
        </Link>
      </div>

      <InformationTable
        details={[
          {
            title: 'Nombre',
            key: 'name',
            align: 'left'
          },
          {
            title: 'Categoría',
            key: 'category',
            align: 'left'
          },
          {
            title: 'Subido por',
            key: 'createdBy'
          },
          {
            title: 'Actualizado por',
            key: 'updatedBy'
          },
          {
            title: 'Visible',
            key: 'visible',
            align: 'center'
          }
        ]}
        data={filteredList.map(video => ({
          name: (
            <Link
              to={`/admin/videos/${video.id}`}
              style={{ color: 'var(--success-color)', fontWeight: 'bold' }}
            >
              {video.name}
            </Link>
          ),
          category: video.category,
          createdBy: video.createdBy,
          updatedBy: video.updatedBy,
          visible: <StatusIcon active={video.visible} />
        }))}
      />
    </>
  )
}

export default VideoList
