import React from 'react'
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  OutlinedInput,
  FilledInput
} from '@mui/material'
import useFastInput from '../../hooks/useFastInput'
import { Search } from '@mui/icons-material'

const Options = {
  outlined: OutlinedInput,
  filled: FilledInput,
  standard: Input
}

const SearchInput = ({
  label = 'Buscar',
  value,
  onChange,
  autoFocus = false,
  useDebounced = false,
  fullWidth = false,
  variant = 'standard',
  onClick = () => {}
}) => {
  const { newInputValue, handleFastChange } = useFastInput({
    value,
    onChange,
    useDebounced
  })
  const InputComponent = Options[variant]

  return (
    <FormControl
      margin='normal'
      fullWidth={fullWidth}
      variant={variant}
    >
      <InputLabel htmlFor='search'>{label}</InputLabel>
      <InputComponent
        id='search'
        value={newInputValue}
        onChange={handleFastChange}
        endAdornment={
          <InputAdornment position='start' onClick={onClick}>
            <Search />
          </InputAdornment>
        }
        autoFocus={autoFocus}
        label={label}
      />
    </FormControl>
  )
}

export default SearchInput
