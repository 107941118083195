import { GET, POST, PUT } from './index'

export const findTraining = () => GET({ url: 'training' })
export const findTrainingItemsByTeacher = (id = '') =>
  GET({ url: 'training/items-by-teacher/' + id })
export const getTrainingItem = (id = '') => GET({ url: 'training/' + id })
export const createTrainingItem = (data = {}) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  return POST({ url: 'training', data: formData, useAcceptAPP_JSON: true })
}
export const updateTrainingItem = (id, data = {}) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })
  return PUT({
    url: 'training/' + id,
    data: formData,
    useAcceptAPP_JSON: true
  })
}
