import React from 'react'
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText
} from '@mui/material'

const options = [
  'Andalucía',
  'Aragón',
  'Asturias (Principado de Asturias)',
  'Baleares (Islas Baleares)',
  'Canarias (Islas Canarias)',
  'Cantabria',
  'Castilla-La Mancha',
  'Castilla y León',
  'Cataluña (Catalunya)',
  'Comunidad Valenciana',
  'Extremadura',
  'Galicia',
  'Madrid (Comunidad de Madrid)',
  'Murcia (Región de Murcia)',
  'Navarra (Comunidad Foral de Navarra)',
  'País Vasco (Euskadi)',
  'La Rioja',
  'Ceuta (Ciudad Autónoma de Ceuta)',
  'Melilla (Ciudad Autónoma de Melilla)'
]

function CommunitySelect({
  value = '',
  onChange,
  label = 'Comunidad autónoma',
  name,
  fullWidth,
  margin = 'none',
  ...rest
}) {
  const { helperText, ...otherRest } = rest || {}
  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      variant='standard'
      {...otherRest}
    >
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        {options.map(category => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default CommunitySelect
