/**
 *
 * @param {{target:{name: string | array, value: any}}} e
 * @returns {{path: string[], value: any} | object}
 */
export function makeCustomChangeEvent(e) {
  if (!e) {
    console.warn(
      '[commonFormChange]: an event ({target:{name: string, value: any}}) is needed'
    )
    return
  }
  if (Array.isArray(e.target.name))
    return { path: e.target.name, value: e.target.value }
  return { path: e.target.name.split('.'), value: e.target.value }
}
/**
 *
 * @param {{target:{name: string | array, checked: boolean}}} e
 * @returns {{path: string[], checked: any} | object}
 */
export function makeCustomCheckChangeEvent(e) {
  if (!e) {
    console.warn(
      '[commonFormChange]: an event ({target:{name: string, value: any}}) is needed'
    )
    return {}
  }
  if (Array.isArray(e.target.name))
    return { path: e.target.name, checked: e.target.checked }
  return { path: e.target.name.split('.'), checked: e.target.checked }
}
