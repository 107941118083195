import React from 'react'
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText
} from '@mui/material'

const options = [
  'Biología',
  'Clases de prueba',
  'Economía',
  'Filosofía',
  'Francés',
  'Física',
  'Fundamentos del arte',
  'Geografía',
  'Griego',
  'Historia',
  'Historia del arte',
  'Inglés',
  'Inglés (Idioma)',
  'Latín',
  'Lengua',
  'Matemáticas',
  'Matemáticas (Primaria)',
  'Química',
  'Técnicas de Estudio',
  'Parte Cientifico-Técnica',
  'Parte Sociolingüística',
  'Otro'
]
function CategorySelect({
  value = '',
  onChange,
  label = 'Categoría',
  name,
  fullWidth,
  margin = 'none',
  ...rest
}) {
  const { helperText, ...otherRest } = rest || {}
  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      variant='standard'
      {...otherRest}
    >
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        {options.map(category => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default CategorySelect
