import { uniq } from 'ramda'
import { useState } from 'react'

export function useTags(initialTags = []) {
  const [tags, setTags] = useState(initialTags)
  const [inputTagValue, setInputTagValue] = useState('')

  const handleInputTagValueChange = e => setInputTagValue(e.target.value)

  const handleAddTag = e => {
    if (inputTagValue.trim() !== '' && e.key === 'Enter') {
      setTags(uniq([...tags, inputTagValue]))
      setInputTagValue('')
    }
  }

  const handleDeleteTag = tagToDelete => {
    setTags(tags.filter(tag => tag !== tagToDelete))
  }
  return {
    tags,
    inputTagValue,
    handleInputTagValueChange,
    handleAddTag,
    handleDeleteTag
  }
}
