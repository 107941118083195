import { useCallback, useEffect, useRef } from 'react'
import { pick } from 'ramda'
import { examTypes } from '../views/admin/Exams/ui/ExamTypeSelect'
import useFormManager from './useFormManager'
import { useTags } from './useTags'

const REQUIRED_FIELDS_CREATE = ['name', 'type', 'file', 'category', 'option']
const REQUIRED_FIELDS_EDIT = ['name', 'type', 'category', 'option']
const REQUIRED_FIELDS_EvAU = ['community', 'year']
const REQUIRED_FIELDS_ESO = ['community', 'scope', 'year']
const REQUIRED_FIELDS_CAMBRIDGE = ['section']
const REQUIRED_FIELDS_PCE = ['year']
const OTHER_COMMON_FIELDS = [
  'id',
  'name',
  'url',
  'hashId',
  'filename',
  'size',
  'type',
  'category',
  'option',
  'visible',
  'solved',
  'description',
  'archived',
  'archivedReason'
]
const DESCRIPTION_LIMIT = 1200

export default function useExamForm({ isEditMode, exam, onSubmit }) {
  const inputFileRef = useRef()
  const {
    form,
    hasPendingRequiredFields,
    handleFormChange,
    handleUploadFile,
    handleCheckChange,
    getErrorPropsByRequiredField,
    clearRequiredFields
  } = useFormManager(
    isEditMode ? REQUIRED_FIELDS_EDIT : REQUIRED_FIELDS_CREATE,
    exam
  )
  const {
    tags,
    inputTagValue,
    handleInputTagValueChange,
    handleAddTag,
    handleDeleteTag
  } = useTags(exam.tags)

  const commonRequiredFields = isEditMode
    ? REQUIRED_FIELDS_EDIT
    : REQUIRED_FIELDS_CREATE

  const handleSubmit = useCallback(() => {
    let data = {}
    switch (form.type) {
      case examTypes.EvAU: {
        data = pick([...commonRequiredFields, ...REQUIRED_FIELDS_EvAU], form)
        break
      }
      case examTypes['Graduado ESO']: {
        data = pick([...commonRequiredFields, ...REQUIRED_FIELDS_ESO], form)
        break
      }
      case examTypes['PCE']: {
        data = pick([...commonRequiredFields, ...REQUIRED_FIELDS_PCE], form)
        break
      }
      case examTypes['Cambridge B2']:
      case examTypes['Cambridge C1']: {
        data = pick(
          [...commonRequiredFields, ...REQUIRED_FIELDS_CAMBRIDGE],
          form
        )
        break
      }

      default: {
        data = pick(commonRequiredFields, form)
        break
      }
    }
    data = { ...pick(OTHER_COMMON_FIELDS, form), ...data, tags }
    onSubmit(data)
  }, [commonRequiredFields, form, onSubmit, tags])

  const handleDeleteFile = useCallback(() => {
    handleFormChange({ target: { name: 'file', value: null } })
    handleFormChange({ target: { name: 'filename', value: null } })
  }, [handleFormChange])

  const handleDescriptionChange = useCallback(
    e => {
      if (e.target.value.length > DESCRIPTION_LIMIT) return
      handleFormChange(e)
    },
    [handleFormChange]
  )

  useEffect(() => {
    switch (form.type) {
      case examTypes.EvAU: {
        clearRequiredFields([...commonRequiredFields, ...REQUIRED_FIELDS_EvAU])
        break
      }
      case examTypes['Graduado ESO']: {
        clearRequiredFields([...commonRequiredFields, ...REQUIRED_FIELDS_ESO])
        break
      }
      case examTypes['PCE']: {
        clearRequiredFields(commonRequiredFields)
        break
      }
      case examTypes['Cambridge B2']:
      case examTypes['Cambridge C1']: {
        clearRequiredFields([
          ...commonRequiredFields,
          ...REQUIRED_FIELDS_CAMBRIDGE
        ])
        break
      }

      default:
        break
    }
  }, [clearRequiredFields, commonRequiredFields, form.type])
  return {
    inputFileRef,
    form,
    tags,
    inputTagValue,
    handleInputTagValueChange,
    handleFormChange,
    getErrorPropsByRequiredField,
    handleSubmit,
    handleDeleteFile,
    handleDescriptionChange,
    handleUploadFile,
    handleCheckChange,
    hasPendingRequiredFields,
    handleAddTag,
    handleDeleteTag
  }
}
