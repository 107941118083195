import React from 'react'
import { times } from 'ramda'
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText
} from '@mui/material'

const YearSelector = ({
  value,
  onChange,
  label = 'Año',
  name,
  fullWidth,
  margin = 'none',
  initialYear = 2020,
  ...rest
}) => {
  const { helperText, ...otherRest } = rest || {}
  const length = new Date().getFullYear() - initialYear + 1
  const years = times(i => new Date().getFullYear() - i, length)
  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      variant='standard'
      {...otherRest}
    >
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        {years.map(year => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default YearSelector
