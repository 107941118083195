import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NotebookForm from '../ui/NotebookForm'
import Header from '../../../../components/Header/Header'
import { createNotebook } from '../../../../api/notebooks'
import { useMessageSnackbarActions } from '../../../../context/MessageProvider'
import '../Common.css'

function NotebookCreate() {
  const navigate = useNavigate()
  const [isSaving, setIsSaving] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const handleSave = newNotebook => {
    setIsSaving(true)
    createNotebook(newNotebook)
      .then(() => {
        setIsSaving(false)
        navigate('/admin/notebooks')
        setSuccessMessage()
      })
      .catch(e => {
        setIsSaving(false)
        console.error('Error creating notebook: ', e)
        setErrorMessage(e.message)
      })
    }

  return (
    <>
      <Header text='Gestión' />
      <div className='adjust_form'>
        <NotebookForm
          title='Añadir cuadernillo'
          onSubmit={handleSave}
          isSaving={isSaving}
        />
      </div>
    </>
  )
}

export default NotebookCreate
