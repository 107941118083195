import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import './InformationTable.css'

const validateDetails = details => {
  details.forEach(detail => {
    if (detail.title === null || detail.title === undefined) {
      console.warn(
        `You have not indicated a column's "title" field for column ${JSON.stringify(
          detail
        )}.`
      )
    }
    if (!detail.key) {
      console.warn(
        `You have not indicated a column's "key" field for column ${JSON.stringify(
          detail
        )}.`
      )
    }
  })
}

const InformationTable = ({
  className = '',
  details = [],
  data = [],
  ...rest
}) => {
  validateDetails(details)

  const [order, setOrder] = useState('desc')
  const [orderField, setOrderField] = useState('')

  const changeSortOrder = field => () => {
    if (orderField === field) setOrder(order === 'desc' ? 'asc' : 'desc')
    else {
      setOrderField(field)
      setOrder('desc')
    }
  }

  const TableColumnTitle = ({ detail }) => {
    const { title, key } = detail
    if (detail.sortDisabled) return title
    return (
      <TableSortLabel
        active={orderField === title}
        direction={order}
        onClick={changeSortOrder(key)}
      >
        {title}
      </TableSortLabel>
    )
  }
  return (
    <Table className={`InformationTable ${className}`} {...rest}>
      <TableHead>
        <TableRow>
          {details.map((detail, index) => (
            <TableCell
              className='InformationTable-header-cell'
              align={detail.align || 'center'}
              key={detail.title}
              style={detail.style || {}}
            >
              <TableColumnTitle detail={detail} />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data
          .sort((a, b) => {
            const sortDetail = details.find(detail => detail.key === orderField)
            if (!sortDetail) return 0
            const { key } = sortDetail
            if (sortDetail.sortFunction) {
              const result = sortDetail.sortFunction(a[key], b[key])
              return order === 'desc' ? -1 * result : result
            }
            if (a[key] < b[key]) return order === 'desc' ? -1 : 1
            if (a[key] > b[key]) return order === 'desc' ? 1 : -1
            return 0
          })
          .map((row, index) => (
            <TableRow key={index} style={row?.style}>
              {details.map((detail, index) => (
                <TableCell
                  className={detail.className || ''}
                  key={detail.key}
                  align={detail.align || 'center'}
                >
                  {row[detail.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

export default InformationTable
