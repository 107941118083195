import React, { useCallback } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  TextField
} from '@mui/material'
import { Save } from '@mui/icons-material'
import useFormManager from '../../../../hooks/useFormManager'
import LoadingButton from '../../../../components/shared/LoadingButton'

const REQUIRED_FIELDS = ['name', 'email', 'role']

function UserForm({ user = {}, title = '', isSaving = false, onSubmit }) {
  const {
    handleFormChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    form
  } = useFormManager(REQUIRED_FIELDS, user)

  const handleSubmit = useCallback(() => {
    onSubmit(form)
  }, [form, onSubmit])
  return (
    <Card className='bordered_shadow' variant='outlined'>
      <CardHeader title={title} />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '10px'
        }}
      >
        <TextField
          name='name'
          margin='dense'
          label='Nombre'
          variant='standard'
          value={form.name || ''}
          fullWidth
          disabled
          {...getErrorPropsByRequiredField('name')}
        />
        <TextField
          name='email'
          margin='dense'
          label='Correo'
          variant='standard'
          value={form.email || ''}
          fullWidth
          disabled
          {...getErrorPropsByRequiredField('email')}
        />
        <TextField
          name='description'
          margin='dense'
          label='Descripción'
          variant='standard'
          value={form.description || ''}
          onChange={handleFormChange}
          maxRows={5}
          multiline
          fullWidth
        />
        <div>
          <FormControlLabel
            margin='dense'
            name='active'
            control={<Checkbox checked={form.active || false} />}
            label='Activo'
            disabled
          />
        </div>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <LoadingButton
          label='Guardar'
          onClick={handleSubmit}
          isLoading={isSaving}
          disabled={hasPendingRequiredFields}
          startIcon={<Save />}
        />
      </CardActions>
    </Card>
  )
}

export default UserForm
