import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import logo from '../../assets/mundoestudiante-logo-texto.png'
import cleanedLogo from '../../assets/mundoestudiante-logo-sintexto.png'
import useBreakPoint from '../../hooks/useBreakPoint'
import MainMenu from '../MainMenu'
import './Header.css'

const Header = ({ text = '' }) => {
  const { isMobile } = useBreakPoint()
  return (
    <header className='Header'>
      <Link to='/'>
        <img
          className='Header-logo'
          src={isMobile ? cleanedLogo : logo}
          alt='Academias mundoestudiante'
        />
      </Link>
      <Tooltip title={text}>
        <div className='Header-info'>{text}</div>
      </Tooltip>
      <div className='Header-menu'>
        <MainMenu />
      </div>
      {!isMobile && (
        <div className='Header-navigation'>
          <NavLink className={({ isActive })=> isActive ? 'active--link' : ''} to='/'>
            Cuadernillos
          </NavLink>
          <NavLink className={({ isActive })=> isActive ? 'active--link' : ''} to='/examenes'>
            Exámenes
          </NavLink>
          <NavLink className={({ isActive })=> isActive ? 'active--link' : ''} to='/videos'>
            Videos
          </NavLink>
        </div>
      )}
    </header>
  )
}
export default Header
