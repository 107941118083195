import React from 'react'
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  FormHelperText
} from '@mui/material'

const options = ['Científico', 'Lengua', 'Inglés', 'Social']
function ScopeSelect({
  value = '',
  onChange,
  label = 'Ámbito',
  name,
  fullWidth,
  margin = 'none',
  ...rest
}) {
  const { helperText, ...otherRest } = rest || {}
  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      variant='standard'
      {...otherRest}
    >
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        {options.map(category => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default ScopeSelect
