import { GET, POST, PUT } from './index'

export const findNotebooks = () => GET({ url: 'notebooks' })
export const findVisibleNotebooks = () => GET({ url: 'notebooks/visible' })
export const getNotebook = (id = '') => GET({ url: 'notebooks/' + id })
export const createNotebook = (data = {}) => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    if (key === 'tags')
      data[key].forEach(tag => {
        formData.append('tags', tag)
      })
    else formData.append(key, data[key])
  })
  return POST({ url: 'notebooks', data: formData, useAcceptAPP_JSON: true })
}
export const updateNotebook = (id, data = {}) => {
  return PUT({
    url: 'notebooks/' + id,
    data
  })
}
