import { CircularProgress } from '@mui/material'
import React from 'react'

function Spinner({ height = '100vh' }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height
      }}
    >
      <CircularProgress size={70} />
    </div>
  )
}

export default Spinner
