import React from 'react'
import Header from '../../components/Header/Header'
import Card from '../../components/Card/Card'
import { useVisibleData } from '../../context/BrainProvider'
import colors from '../../data/colors'
import styles from './Home.module.css'

const Home = () => {
  const { notebooks } = useVisibleData()
  return (
    <>
      <Header text='Inicio' />
      <div className={styles.innerContainer}>
        {notebooks
          .map(notebook => notebook.category)
          .filter(
            (category, index, categories) =>
              categories.indexOf(category) === index
          )
          .map(asignatura => (
            <Card
              key={asignatura}
              link={`asignaturas/${asignatura.toLowerCase()}`}
              color={
                colors.find(
                  color => color.name.toLowerCase() === asignatura.toLowerCase()
                )?.color
              }
              content={asignatura}
            />
          ))}
      </div>
    </>
  )
}

export default Home
