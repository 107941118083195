import Header from '../../components/Header/Header'
import Card from '../../components/Card/Card'
import colors from '../../data/colors'
import { useVisibleData } from '../../context/BrainProvider'
import { useParams } from 'react-router-dom'

const AsignaturasTipo = () => {
  const { exams } = useVisibleData()
  const { id } = useParams()

  return (
    <>
      <Header text={id} />
      <div
        style={{
          display: 'grid',
          margin: '20px',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridGap: '20px'
        }}
      >
        {exams
          .filter(exam => exam.type === id)
          .map(exam => exam.category)
          .sort()
          .filter(
            (category, index, categories) =>
              categories.indexOf(category) === index
          )
          .map(category => (
            <Card
              key={category}
              link={category}
              color={
                colors.find(
                  color => color.name.toLowerCase() === category.toLowerCase()
                )?.color
              }
              content={category}
            />
          ))}
      </div>
    </>
  )
}

export default AsignaturasTipo
