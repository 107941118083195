import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/mundoestudiante-logo-texto.png'
import cleanedLogo from '../../assets/mundoestudiante-logo-sintexto.png'
import useBreakPoint from '../../hooks/useBreakPoint'
import './Card.css'

const Card = ({ link, color, content, ...rest }) => {
  const { isMobile } = useBreakPoint()
  return (
    <div className='Card' {...rest}>
      <Link to={link}>
        <div
          className='Card-top'
          style={{
            backgroundColor: color,
            backgroundImage: `url(${isMobile ? cleanedLogo : logo})`,
            backgroundSize: isMobile ? '40%' : undefined
          }}
        />
        <div className='Card-bottom'>
          <div className='Card-title'>
            <p>{content}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}
export default Card
