import { GET, POST, PUT } from './index'

export const findVideos = () => GET({ url: 'videos' })
export const findVisibleVideos = () => GET({ url: 'videos/visible' })
export const getVideo = (id = '') => GET({ url: 'videos/' + id })
export const createVideo = (data = {}) =>
  POST({
    url: 'videos',
    data
  })
export const updateVideo = (id, data = {}) => PUT({ url: 'videos/' + id, data })
