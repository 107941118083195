import React from 'react'
import { Done, Clear } from '@mui/icons-material'
const StatusIcon = ({
  active,
  positiveText = 'Activo',
  negativeText = 'Inactivo'
}) => {
  if (active) return <Done title={positiveText} style={{ color: '#8cc63f' }} />
  else return <Clear title={negativeText} style={{ color: '#f0513c' }} />
}

export default StatusIcon
