import React from 'react'
import { CircularProgress, Button } from '@mui/material'

const noop = () => {}

const LoadingButton = ({
  onClick = noop,
  label = 'Aceptar',
  disabled = false,
  isLoading = false,
  startIcon = undefined || null || <div></div>,
  variant = 'contained',
  size = 'medium',
  color = 'primary'
}) => {
  if (isLoading) return <CircularProgress size={30} />
  else
    return (
      <Button
        onClick={onClick}
        variant={variant}
        color={color}
        disabled={disabled}
        startIcon={startIcon}
        size={size}
      >
        {label}
      </Button>
    )
}
export default LoadingButton
