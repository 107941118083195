import React, { useMemo } from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField
} from '@mui/material'
import { Save } from '@mui/icons-material'
import useExamForm from '../../../../hooks/useExamForm'
import LoadingButton from '../../../../components/shared/LoadingButton'
import CategorySelect from '../../../../components/shared/CategorySelect'
import CommunitySelect from '../../../../components/shared/CommunitySelect'
import YearSelector from '../../../../components/shared/YearSelector'
import ScopeSelect from '../../../../components/shared/ScopeSelect'
import TagList from '../../../../components/shared/TagList'
import CambridgeSectionSelect from '../../../../components/shared/CambridgeSectionSelect'
import ExamTypeSelect, { examTypes } from './ExamTypeSelect'
import ExamOptionSelect from './ExamOptionSelect '

function ExamForm({
  exam = {},
  title = '',
  isSaving = false,
  isEditMode = false,
  onSubmit
}) {
  const {
    inputFileRef,
    form,
    tags,
    inputTagValue,
    handleInputTagValueChange,
    handleFormChange,
    getErrorPropsByRequiredField,
    handleSubmit,
    handleDeleteFile,
    handleDescriptionChange,
    handleUploadFile,
    handleCheckChange,
    hasPendingRequiredFields,
    handleAddTag,
    handleDeleteTag
  } = useExamForm({
    isEditMode,
    exam,
    onSubmit
  })

  const children = useMemo(() => {
    switch (form.type) {
      case examTypes.EvAU: {
        return (
          <>
            <CommunitySelect
              name='community'
              value={form.community || ''}
              onChange={handleFormChange}
              fullWidth
              {...getErrorPropsByRequiredField('community')}
            />
            <YearSelector
              name='year'
              value={form.year || ''}
              initialYear={2014}
              onChange={handleFormChange}
              fullWidth
              {...getErrorPropsByRequiredField('year')}
            />
          </>
        )
      }
      case examTypes['Graduado ESO']: {
        return (
          <>
            <CommunitySelect
              name='community'
              value={form.community || ''}
              onChange={handleFormChange}
              fullWidth
              {...getErrorPropsByRequiredField('community')}
            />
            <YearSelector
              name='year'
              value={form.year || ''}
              initialYear={2014}
              onChange={handleFormChange}
              fullWidth
              {...getErrorPropsByRequiredField('year')}
            />
            <ScopeSelect
              name='scope'
              value={form.scope || ''}
              onChange={handleFormChange}
              fullWidth
              {...getErrorPropsByRequiredField('scope')}
            />
          </>
        )
      }
      case examTypes['PCE']: {
        return (
          <YearSelector
            name='year'
            value={form.year || ''}
            initialYear={2014}
            onChange={handleFormChange}
            fullWidth
            {...getErrorPropsByRequiredField('year')}
          />
        )
      }
      case examTypes['Cambridge B2']:
      case examTypes['Cambridge C1']: {
        return (
          <>
            <CambridgeSectionSelect
              name='section'
              value={form.section || ''}
              onChange={handleFormChange}
              fullWidth
              {...getErrorPropsByRequiredField('section')}
            />
          </>
        )
      }

      default:
        return null
    }
  }, [
    form.community,
    form.scope,
    form.section,
    form.type,
    form.year,
    getErrorPropsByRequiredField,
    handleFormChange
  ])

  return (
    <Card className='bordered_shadow' variant='outlined'>
      <CardHeader title={title} />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '10px'
        }}
      >
        <ExamTypeSelect
          name='type'
          value={form.type || ''}
          onChange={handleFormChange}
          disabled={isEditMode}
          fullWidth
          {...getErrorPropsByRequiredField('type')}
        />
        <CategorySelect
          name='category'
          value={form.category || ''}
          onChange={handleFormChange}
          fullWidth
          {...getErrorPropsByRequiredField('category')}
        />
        <TextField
          name='name'
          margin='dense'
          label='Nombre'
          variant='standard'
          value={form.name || ''}
          onChange={handleFormChange}
          fullWidth
          {...getErrorPropsByRequiredField('name')}
        />
        {children}
        <ExamOptionSelect
          name='option'
          margin='dense'
          label='Opción'
          variant='standard'
          value={form.option || ''}
          onChange={handleFormChange}
          fullWidth
          {...getErrorPropsByRequiredField('option')}
        />
        <TextField
          name='description'
          margin='dense'
          label='Descripción'
          variant='standard'
          value={form.description || ''}
          onChange={handleDescriptionChange}
          maxRows={6}
          helperText={`${form.description?.length || 0}/1200`}
          multiline
          fullWidth
        />
        {!isEditMode && (
          <div style={{ margin: '0 auto', marginTop: 15 }}>
            {form.file ? (
              <Chip label={form.file.name} onDelete={handleDeleteFile} />
            ) : (
              <Button
                color='secondary'
                onClick={() =>
                  inputFileRef.current.click()
                }
                variant='contained'
              >
                Seleccionar archivo
                <input
                  name='file'
                  type='file'
                  ref={inputFileRef}
                  hidden
                  onChange={e =>
                    handleUploadFile({
                      target: {
                        name: e.target.name,
                        files: e.target?.files?.[0]
                      }
                    })
                  }
                  accept='application/pdf'
                />
              </Button>
            )}
          </div>
        )}

        <div>
          <FormControlLabel
            margin='dense'
            name='visible'
            control={
              <Checkbox
                checked={form.visible || false}
                onChange={handleCheckChange}
              />
            }
            label='Activo'
          />
          <FormControlLabel
            margin='dense'
            name='solved'
            control={
              <Checkbox
                checked={form.solved || false}
                onChange={handleCheckChange}
              />
            }
            label='Resuelto'
          />
          {isEditMode && (
            <FormControlLabel
              margin='dense'
              name='archived'
              control={
                <Checkbox
                  checked={form.archived || false}
                  onChange={handleCheckChange}
                />
              }
              label='Archivado'
              disabled={exam.archived}
            />
          )}
        </div>

        {isEditMode && !!form.archived && (
          <div>
            <TextField
              name='archivedReason'
              margin='dense'
              label='Razón archivado'
              variant='standard'
              value={form.archivedReason || ''}
              onChange={handleFormChange}
              maxRows={5}
              multiline
              fullWidth
            />
            {form.archivedAt && (
              <p> Archivado a {new Date(form.archivedAt).toLocaleString()}</p>
            )}
          </div>
        )}
        {isEditMode && (
          <a target='_blank' href={form.url} rel='noreferrer'>
            <Button style={{ marginTop: 20, width: '100%' }} color='primary'>
              Ver archivo actual
            </Button>
          </a>
        )}
        <TagList
          tags={tags}
          inputTagValue={inputTagValue}
          onTagDelete={handleDeleteTag}
          onChange={handleInputTagValueChange}
          onTagAdd={handleAddTag}
        />
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <LoadingButton
          label='Guardar'
          onClick={handleSubmit}
          isLoading={isSaving}
          disabled={hasPendingRequiredFields || !!exam?.archived}
          startIcon={<Save />}
        />
      </CardActions>
    </Card>
  )
}

export default ExamForm
