const colors = [
  { name: 'biología', color: 'green' },
  { name: 'economía', color: '#e7d71a' },
  { name: 'filosofía', color: '#ef3136' },
  { name: 'física', color: '#f68a1f' },
  { name: 'francés', color: 'red' },
  { name: 'geografía', color: '#000000' },
  { name: 'historia', color: '#e6e608' },
  { name: 'historia del arte', color: 'brown' },
  { name: 'inglés', color: '#090090' },
  { name: 'inglés (idioma)', color: '#090090' },
  { name: 'latín', color: 'purple' },
  { name: 'lengua', color: '#6f4d9f' },
  { name: 'matemáticas', color: '#2b6fb8' },
  { name: 'matemáticas (primaria)', color: '#2b6fb8' },
  { name: 'química', color: '#f543ed' },
  { name: 'Técnicas de Estudio', color: '#059c76' },
  { name: 'otro', color: '#94bf1f' },

  // TODO: vamos a tener estas categorías?
  { name: 'literatura', color: '#6f4d9f' },
  { name: 'literatura universal', color: '#6f4d9f' },
  { name: 'matemáticas ccss', color: '#2b6fb8' },
  { name: 'dibujo técnico', color: 'grey' },
  { name: 'dibujo tecnico', color: 'grey' },
  { name: 'griego', color: 'pink' },
  { name: 'arte', color: 'cyan' }
]

export default colors
