import React from 'react'
import Header from '../../components/Header/Header'
import { useVisibleData } from '../../context/BrainProvider'
import { useParams } from 'react-router-dom'
import './Video.css'

const Video = () => {
  const { videos } = useVisibleData()
  const { id } = useParams()
  const video = videos.find(video => video.id === id)
  return (
    <>
      <Header text={video?.name} />
      <div className='Video'>
        {video?.url ? (
          <iframe
            title='Video de YouTube'
            className='Video-element'
            frameBorder='0'
            src={video?.url + '?rel=0'}
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        ) : (
          <p>No se ha encontrado el video</p>
        )}
      </div>
    </>
  )
}

export default Video
